

import {
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  NavbarBrand
} from "reactstrap";
import React, { useEffect } from 'react';
import Header from '../../components/Headers/Header';

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
const Uas = (props) => {
    const history = useHistory();

    useEffect(() => {

      if(typeof props.userDetails.coach != "undefined" && (props.userDetails.coach === "true" || props.userDetails.coach ===true)){
  
        
      }
      else{
        if(props.userDetails.ultimateAdvisingStudent === "false" || props.userDetails.ultimateAdvisingStudent === false){
          // history.push("/client/dashboard")
        }
      }
      
    }, [])
    
  return (
    <></>

      //  {/* <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      //     <Container fluid>
      //       <div className="header-body">
      //         <iframe src="https://link.optimizemd.io/widget/bookings/predental-enrollment/30-min" width={1200} height={900} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"></iframe>
      //       </div>
      //     </Container>
      //   </div> */}

        // // <Header></Header>
        //   // <Container className="mt--7" fluid>
        //     {/* <div className="header-body">
        //       <iframe src="https://link.optimizemd.io/widget/bookings/tpc-success/discovery-call" width={1000} height={900} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"></iframe>
        //     </div> */}

        //     // <Row className='mb-3'>
        //     //         <Col>
        //                 {/* <Card className="shadow">
        //                     <CardHeader className="border-0">
        //                     <iframe src="https://link.optimizemd.io/widget/bookings/predental-enrollment/30-min" width={"100%"} height={900} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"></iframe>
        //                     </CardHeader> */}
        //                     {/* <CardBody>
                                
                                
                        //     </CardBody> */}
                        // {/* </Card> */}
                        
    //                 </Col> 
    //             </Row>

    //       </Container>
         
 
    // </>
  )
}
const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
   
  };
};
export default connect(mapStateToProps)(Uas);
