import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { app, db } from "../../firebase";
import {
    Badge,
    List,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner
} from "reactstrap";
import { collection, getDoc, getDocs, query, where, onSnapshot, doc} from "firebase/firestore";
import UserInfo from 'components/Community/UserInfo';
import UserWithImage from 'components/Community/UserWithImage';
const CountsOfPostLikes = (props) => {

    const dispatch = useDispatch()
    const [likes, setlikeCounts] = useState([])
    const [users, setUsers] = useState([])
    const [isHovering, setIsHovering] = useState(null);
     const [isLoading, setLoading] = useState(true)

    //Modal Behaviour 
    const [modal, setModal] = useState(false);
    const modalToggle = () => setModal(!modal);
    const [backdrop, setBackdrop] = useState(true);

    const handleMouseOver = (i) => {
        setIsHovering(i);
        setLoading(true)
        setTimeout(() => setLoading(false), 1000)
       
    };
    const handleMouseOut = (i) => {
        setIsHovering(null);
       
    };

    const showUsersWholike =  () => {
        setBackdrop(true);
        setModal(true)
    }

    useEffect(() => {
        let isLoading = true;
        new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve("Time is up!");
            }, 1000);
        }).then((value) => {
            if(isLoading) {
                setLoading(false);
            }
    });
        // Clean-up:
        return () => {
            isLoading = false;
        }
    }, []);
   
    
    //Real Time Change
    useEffect(() => {
        const countlikes = onSnapshot(
            collection(db, "communityUasLikePosts"), where("postid", "==", props.postid),
            (snapshot) => {
                let likesCount = [];
                snapshot.docs.forEach(async (doc) => { 
                    let likeDoc = doc.data()
                    if (likeDoc.postid == props.postid) {
                        likesCount.push(doc.data()) 
                    } 
                })
                setlikeCounts(likesCount);
            },
            (error) => {
                console.log(error)
            }
        );
        return () => {
            countlikes()
        }
    }, [props])

    return (
         
        <>
            {
                    
                likes.length ?
                    <>
                        {likes.length ?
                            <div  style={{  }}>
                                <Badge color="primary" style={{ borderRadius: '20rem', backgroundColor: '#2643e9', padding: '0.35rem' }} pill>
                                    <i className="fas fa-solid fa-heart text-white "></i>
                                </Badge> 
                                
                                <a  style={{ cursor:'pointer'  }} onClick={showUsersWholike} className="ml-1" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>{likes.length} </a>
                                {
                                    isHovering ? 
                                        
                                    <div className="p-3 text-white " style={{ backgroundColor: '#000', borderRadius: '10px', position: 'absolute', zIndex:'9', opacity: '0.8' , minWidth: '100px' }}>
                                        {
                                            !isLoading ?
                                            <List type="unstyled" >
                                                    {
                                                        likes.map((v, i) => {
                                                            return (
                                                                <li key={v.userHandle}><UserInfo key={v.userHandle} userHandle={v.userHandle} ></UserInfo></li>
                                                            )
                                                        })
                                                    }
                                            </List>
                                            :
                                            <div style={{ width: '3rem' }} className="text-center">
                                                <Spinner size="sm" color="primary" />
                                            </div> 
                                        }
                                    </div>
                                    : ''
                            }
                                   
                            </div>
                            : ''} 
                    </> 
                : ''
            }   
            
        <Modal isOpen={modal} toggle={modalToggle}  backdrop={backdrop} size="sm">
            <ModalHeader toggle={modalToggle}></ModalHeader>
            <ModalBody>
                {
                    likes.map((v, i) => {
                        return (
                            <div className="d-flex  " key={i }>
                                <UserWithImage key={v.userHandle} userHandle={v.userHandle} ></UserWithImage>
                            </div>
                        )
                    })   
                }
                    
            </ModalBody>
               
        </Modal>
          
        </>

    )

}

const mapStateToProps = state  => {
    return {
        userDetails: state.loginAuth.userDetails,
    }  
}

export default connect(mapStateToProps)(CountsOfPostLikes)
