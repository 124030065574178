/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Container,
  Row,
  Button
} from "reactstrap";
// core components
import Header from "components/Headers/Header";
import { useEffect, useState } from "react";

import { connect, useDispatch } from "react-redux";

import { fetchSelectSchoolForSecondaryEssays, deleteSelectedSchoolForSecondaryEssay, deleteSelectedSchoolForSecondaryEssayReset, updateSecondaryStatus, updateSecondaryStatusReset } from "redux/SecondaryEssays/SecondaryEssaysActions";

import SecondaryEssaysPages from "components/Pages/SecondaryEssaysPages";
import Swal from 'sweetalert2'

import { secondaryEssayStatus } from "util/DataList";
import Select , { StylesConfig } from 'react-select';
import {customDropdownWithColor} from "util/reactCustomStyles";

import Plans from './Plans';
import { useHistory } from 'react-router-dom';
import { studentsLifetimePlan } from "util/DataList";
const MyApplication = (props) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [selectedSchoolForSecondaryEssays, setSelectedSchoolForSecondaryEssays] = useState([]);


  const [selectedSchoolForWriteSecondaryEssays, setSelectedSchoolForWriteSecondaryEssays] = useState("");


  useEffect(() => {
    dispatch(fetchSelectSchoolForSecondaryEssays({"userId" : props.userDetails.userId}))
  }, [])
  

  useEffect(() => {

    let data = props.fetchSelectSchoolForSecondaryEssays.selectschoolforsecondaryessays;
    data.map((value,index) => 
    {
      if(typeof value.status == "undefined"){
        value.status = "pre-submission";
      }
      
    })

    setSelectedSchoolForSecondaryEssays(data);
    
  }, [props.fetchSelectSchoolForSecondaryEssays])


  const handleStatusChange = (e) =>{

    
    let data = selectedSchoolForSecondaryEssays;
    data[e.key].status = e.value
    setSelectedSchoolForSecondaryEssays(data)
    
    dispatch(updateSecondaryStatus({selectSchoolForSecondaryEssayId: e.selectSchoolForSecondaryEssayId, status: e.value}))

  }
  const onClickWriteEssay = (e, data) => {
    e.preventDefault();
    
    setSelectedSchoolForWriteSecondaryEssays(data);
  }


  const onClickBackToMyApplication = (e, data) => {
    e.preventDefault();
    
    setSelectedSchoolForWriteSecondaryEssays(data);
  }

  const onClickDeleteSelectedSchoolForSecondaryEssay = (e, data) => {

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteSelectedSchoolForSecondaryEssay(data))

      }
    })

    

  }


  useEffect(() => {
    if(props.deleteSelectedSchoolForSecondaryEssay.loading){
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });
  
    }
    else{
      if(props.deleteSelectedSchoolForSecondaryEssay.selectschoolforsecondaryessays.length && typeof props.deleteSelectedSchoolForSecondaryEssay.error === "undefined"){
          
        Swal.fire({
            title: 'Deleted!',
            text: 'Successfully deleted selected school!',
            icon : 'success',
            timer: 3000      
          }
        )
        dispatch(fetchSelectSchoolForSecondaryEssays({"userId" : props.userDetails.userId}))
        dispatch(deleteSelectedSchoolForSecondaryEssayReset());
        
        
      }
    
      else if(typeof props.deleteSelectedSchoolForSecondaryEssay.error !== "undefined"){
          
          Swal.fire({
              title:'Failed!',
              text:'Failed to delete selected school!',
              icon :'error',
              timer: 3000 
              }
          )            
          dispatch(deleteSelectedSchoolForSecondaryEssayReset());
      }
    }
  }, [props.deleteSelectedSchoolForSecondaryEssay])

  useEffect(() => {
    if(props.updateSecondaryStatus.loading){
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });
  
    }
    else{
      if(props.updateSecondaryStatus.secondarystatus.length && typeof props.updateSecondaryStatus.error === "undefined"){
          
        Swal.fire({
            title: 'Success!',
            text: 'Successfully update status!',
            icon : 'success',
            timer: 3000      
          }
        )
   
        dispatch(updateSecondaryStatusReset());
        
        
      }
    
      else if(typeof props.updateSecondaryStatus.error !== "undefined"){
          
          Swal.fire({
              title:'Failed!',
              text:'Failed to update status!',
              icon :'error',
              timer: 3000 
              }
          )            
          dispatch(updateSecondaryStatusReset());
      }
    }
  }, [props.updateSecondaryStatus])
  
  

 
  const [subscribe, setSubscribe] = useState('unlock');

  const fetchSubscriptionDetails = () => {
    // if(props.userDetails.ultimateAdvisingStudent == true || studentsLifetimePlan.includes(props.userDetails.email)){

    //   setSubscribe('unlock')

    // }
    // else{
    //   if(props.fetchDetailsSubscription.fetchdetailssubscription.length){

    //     const subsDetails = props.fetchDetailsSubscription.fetchdetailssubscription[0]

    //     if(subsDetails.status == "active"){
    //       setSubscribe('unlock')
    //     }
    //     else{
    //       const date = new Date();
    //       const secondsSinceEpoch = Math.floor(date.getTime() / 1000);
          

    //       if(subsDetails.cancel_at_period_end){
    //         if(secondsSinceEpoch > subsDetails.canceled_at){
    //           setSubscribe('lock')
    //         }
    //         else{
    //           setSubscribe('unlock')
    //         }
    //       }
    //       else{
    //         setSubscribe('lock')
    //       }
    //     }

    //   }
    //   else{
    //     setSubscribe('lock')
    //   }
    // }
  }

  useEffect(() => {

    fetchSubscriptionDetails()

  }, [])

  useEffect(() => {

    fetchSubscriptionDetails()

  }, [props.fetchDetailsSubscription])

  
  return (
    
    <>
      
      <Header />
      {selectedSchoolForWriteSecondaryEssays ? <Container className="mt--9 " fluid><a href="" className="text-warning">
      <Button className="shadow" color="info" onClick={(e) => onClickBackToMyApplication(e,"")}><i className="fas fa-arrow-left"></i>&nbsp;&nbsp;Back to My Application</Button></a></Container> : "" }
      {/* Page content */}
      
      <Container className={selectedSchoolForWriteSecondaryEssays ? "mt-4" : "mt--7"} fluid>
      
      {selectedSchoolForWriteSecondaryEssays  == "" ? <>
        {/* Table */}
        <Row>
        
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">My Application (Secondary Essays)</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Institution</th>
                    <th scope="col">Status</th>
                    {/* <th scope="col">Application Deadline</th>
                    <th scope="col">Mean GPA</th>
                    <th scope="col">Mean MCAT</th> */}
                    <th scope="col">Pre-write Secondary</th>
                  </tr>
                </thead>
                <tbody>
                  
                    {
                      selectedSchoolForSecondaryEssays.map((value, key)=>{

                        // const applicationDeadlineFullDate = new Date(value.medicalSchool.overview.priApplLatestDtText); 
                        // const applicationDeadlineDay = applicationDeadlineFullDate.getDate();
                        // const applicationDeadlineMonth = applicationDeadlineFullDate.toLocaleString('default', { month: 'long' });
                        // const applicationDeadlineYear = applicationDeadlineFullDate.getFullYear();


                        // const classesBeginFullDate = new Date(value.medicalSchool.medSchoolInformation.startMonthYear); 
                        // const classesBeginDay = classesBeginFullDate.getDate();
                        // const classesBeginMonth = classesBeginFullDate.toLocaleString('default', { month: 'long' });
                        // const classesBeginYear = classesBeginFullDate.getFullYear();

                        return <tr key={value.medicalSchool.overview.schoolName}>
                          <th scope="row">
                            <Media className="align-items-center">
                              {/* <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={"https://storage.googleapis.com/pre-dental-tracker.appspot.com/school-search-images/"+value.medicalSchool.crestUrl}
                                />
                              </a>  */}
                              <Media>
                                <span className="mb-0 text-sm">
                                  {value.medicalSchool.overview.schoolName}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td   style={{minWidth:"300px"}}>
                            {/* <select
                                id="input-school-year"
                                className="form-control form-control-alternative"
                                defaultValue={value.status}
                                style={{minWidth:"200px"}}
                                onChange={(e) => handleStatusChange({value:e.target.value, key: key, selectSchoolForSecondaryEssayId : value.selectSchoolForSecondaryEssayId })}
                                >
                                {secondaryEssayStatus.map((status, index) => (
                                  <option key={status + index} value={status}>
                                    {status}
                                  </option>
                                ))}
                            </select> */}
                            {subscribe == "lock" ? <><span className="fas fa-lock text-yellow"></span>&nbsp;&nbsp;<a href=""  onClick={e => {e.preventDefault(); history.push('/client/plans')}}>Subscribe to unlock</a></> :
                            <Select 
                              isDisabled={props.adminUser.admin ? true : false}
                              options={secondaryEssayStatus} 
                              styles={customDropdownWithColor }  
                              onChange={(data) => handleStatusChange({value:data.value, key: key, selectSchoolForSecondaryEssayId : value.selectSchoolForSecondaryEssayId })}
                              menuPortalTarget={document.body}
                              value={secondaryEssayStatus.find((option) => option.value === value.status)}
                            />
                            }

                          </td>
                          {/* <td>
                          {applicationDeadlineMonth} {applicationDeadlineDay}, {applicationDeadlineYear}
                          </td> */}
                          {/* <td> */}
                          {/* {value.medicalSchool.medSchoolMatDemo.gpaAcceptedTotalAvg && value.medicalSchool.medSchoolMatDemo.gpaAcceptedTotalAvg != "0.00" ? value.medicalSchool.medSchoolMatDemo.gpaAcceptedTotalAvg : "Not Available"} */}
                          {/* </td> */}
                          {/* <td> */}
                          {/* {value.medicalSchool.medSchoolMatDemo.mcatAcceptedTotalAvg && value.medicalSchool.medSchoolMatDemo.mcatAcceptedTotalAvg != "0.0" ? value.medicalSchool.medSchoolMatDemo.mcatAcceptedTotalAvg : "Not Available"} */}
                          {/* </td> */}
                          <td>
                            {props.adminUser.admin ? 
                                <a href="#pablo" className="btn btn-sm btn-primary" onClick={e => onClickWriteEssay(e, value.schoolSearchId)}><i className="fa fa-eye"></i> View</a>
                              :
                              <>
                                {subscribe == "lock" ? <><span className="fas fa-lock text-yellow"></span>&nbsp;&nbsp;<a href="" onClick={e => {e.preventDefault(); history.push('/client/plans')}}>Subscribe to unlock</a></> :
                                <>
                                  <a href="#pablo" className="btn btn-sm btn-primary" onClick={e => onClickWriteEssay(e, value.schoolSearchId)}><i className="fa fa-pen"></i> Edit secondary</a>
                                  <a href="#pablo" className="btn btn-sm btn-danger" onClick={e => onClickDeleteSelectedSchoolForSecondaryEssay(e, {schoolSearchId: value.schoolSearchId , userId: props.userDetails.userId})}><i className="fa fa-trash"></i> Delete</a>
                                </>}
                              </>
                            }
                          </td>
                        </tr>
                      })
                    }
                  
                </tbody>
              </Table>
              <CardFooter></CardFooter>
            </Card> 
          </div>
        </Row>
        </>: <SecondaryEssaysPages secondaryEssays={selectedSchoolForSecondaryEssays} selectedSchoolForWriteSecondaryEssays={selectedSchoolForWriteSecondaryEssays}></SecondaryEssaysPages>}
      </Container>
      <Plans></Plans>
    </>
  );
};

const mapStateToProps = state  => {
  return {

      fetchSelectSchoolForSecondaryEssays : state.fetchSelectSchoolForSecondaryEssays,
      userDetails : state.loginAuth.userDetails,
      deleteSelectedSchoolForSecondaryEssay : state.deleteSelectedSchoolForSecondaryEssay,
      updateSecondaryStatus : state.updateSecondaryStatus,
      fetchDetailsSubscription : state.fetchDetailsSubscription,
      adminUser : state.adminUser
      
  }
    
}

export default connect(mapStateToProps)(MyApplication);
