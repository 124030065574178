import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import moment from 'moment-timezone';
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    Input,
    Image,
    Form,
} from "reactstrap";

import TextareaAutosize from 'react-textarea-autosize';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { app, db } from "../../firebase";
import { getDocs, getDoc, setDoc, addDoc, collection, query, where, onSnapshot, doc, orderBy, limit } from "firebase/firestore";
import Reply from 'components/Community/UasReply';
const Replies = (props) => {

    const dispatch = useDispatch()
    const [repliesArr, setReplies] = useState([])

    useEffect(() => {
        const unsub = onSnapshot(query(collection(db, "communityUasReplies"), where("commentid", "==",props.item.commentDocId ), orderBy("createdAt","desc")), (snapshot) =>
            setReplies(snapshot.docs.map((doc) => ({ ...doc.data(), replyDocId: doc.id })))
        );
        return unsub
    },[]);

    return (
        <>
            {
                repliesArr.length > 0 ?
                    repliesArr.map((reply, key) => {
                        return (                 
                        <Reply
                            key={reply.replyDocId}
                            item={reply}
                            postDetails={props.postDetails}
                        />
                        )      
                       
                    })
                : ''
            }
                     
        </>
      
    )

}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
    }  
}

export default connect(mapStateToProps)(Replies)
