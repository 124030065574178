import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  CardTitle, 
  CardSubtitle,
  FormGroup,
  Input,
  CardFooter,
  Button,
  Table,
  Badge,
TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Alert,
   Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from "reactstrap";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import {
  fetchStudentRequestTutorHistory,
} from "redux/ManageTutorRequests/ManageTutorRequestsActions";
import UploadTutorVideoFileModal from "components/Modal/UploadTutorVideoFileModal";
import classnames from "classnames";
import DataTable from "react-data-table-component";
import { customDropdownWithBorder } from "util/reactCustomStyles";
const ManageStudentRequestHistory = (props) => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState([]);
    const [requestTutorHistory, setRequestTutorHistory] = useState([]);

    const [itemDetails, setItemDetails] = useState();

    //Modal Behaviour
    const [modal, setModal] = useState(false);
    const modalToggle = () => setModal(!modal);
    const [backdrop, setBackdrop] = useState(true);

    const showRequestDetails = (e,item) => {
      console.log(item)
      setItemDetails(item)
      setBackdrop(true);
      setModal(true);
    };
    useEffect(() => {
        dispatch(
            fetchStudentRequestTutorHistory({
            handle: props.userDetails.handle,
            })
        );
    }, []);

    useEffect(() => {
        if (props.fetchStudentRequestTutorHistory.loading) {
        Swal.fire({
            allowOutsideClick: false,
            didOpen: () => {
            Swal.showLoading();
            },
        });
        } else {
        Swal.close();
        }
        if (
        props.fetchStudentRequestTutorHistory.fetchstudentrequesttutorhistory
            .length
        ) {
        
          const fetchstudentrequesttutorhistory = props.fetchStudentRequestTutorHistory.fetchstudentrequesttutorhistory[0]
          fetchstudentrequesttutorhistory.sort((a, b) => moment(b.starttime).valueOf() - moment(a.starttime).valueOf());
          
          setRequestTutorHistory(
              fetchstudentrequesttutorhistory
          );
              setData(  fetchstudentrequesttutorhistory);
          setFilter(  fetchstudentrequesttutorhistory);
        }
    }, [props.fetchStudentRequestTutorHistory]);


    const convertTimeToUTC = (time) => {
      return moment(moment(time).utcOffset(0, false), "HH:mm")
    }
    const convertTimeToLocal = (time) => {
      return moment(moment.tz(time, "America/New_York"), "HH:mm")
    }


    const columns = [
        {
        name: "Student",
        selector: (row) => row.user.name,
        wrap: true,
        },
        {
          name: <>Date&nbsp;<span className='text-warning'>(EST/EDT)</span> </>,
          selector: (row) => {
        // return moment(row.starttime).format('ll') != moment(row.endtime).format('ll') ? moment(row.starttime).format('ll')+" - "+moment(row.endtime).format('ll'):  moment(row.starttime).format('ll');
          return moment.tz(row.starttime,"America/New_York").format('ll');
          
        },
        },
        {
        name: <>Time&nbsp;<span className='text-warning'>(EST/EDT)</span> </>,
        selector: (row) => {
          return convertTimeToLocal(row.starttime).format("h:mm A") + ' - ' + convertTimeToLocal(row.endtime).format("h:mm A")
        },
        wrap: true,
        },
        {
        name: "Type",
        selector: (row) => {
            return  row.requestType.toUpperCase() 
        },
        wrap: true,
        },
        {
        name: "Duration",
        selector: (row) => {
            return  row.duration 
        },
        wrap: true,
        },
        {
        name: "Status",
        selector: (row) => (
            <Badge color="" className="badge-dot mr-4">
            <i
                className={
                row.status == "pending"
                    ? "bg-info"
                    : row.status == "approved"
                    ? "bg-success"
                    : row.status == "disapproved"
                    ? "bg-danger"
                    : "bg-warning"
                }
            />
            {row.status}
            </Badge>
        ),
        },
        {
        name: "Action",
        cell: (row) => (
          <button
              onClick={(e) => showRequestDetails(e, row)}
                  className="btn btn-sm btn-primary"
              >
              Details
          </button>
                
        ),
            wrap: true,
            center: true,
            width: "300px",
        },
    ];
    
    const tableHeaderstyle = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px",
                // backgroundColor: "#ccc",
            },
        },
    };

    const convertDate = (item) => {
        return moment(item).utcOffset(0, false).format("ll");
    };


   return (
    <>
    <Card className="shadow">
      <CardBody>
        <CardTitle tag="h4" >STUDENT REQUESTS HISTORY</CardTitle>
        <CardSubtitle tag="h5" className="mb-2 text-muted">List of all records of your students past sessions </CardSubtitle>
        {data.length ? (
          
          <DataTable
            customStyles={tableHeaderstyle}
            columns={columns}
            data={filter}
            pagination
            wrap
            // selectableRows
            // fixedHeader
            selectableRowsHighlight
            highlightOnHover
            subHeader
            subHeaderComponent={
              <input
                type="text"
                className="w-50 form-control"
                placeholder="Search a keyword content..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            }
            subHeaderAlign="right"
          />
        ) : (
          <Alert color="primary">No data available.</Alert>
        )}
      </CardBody>
    </Card>
     <Modal
        isOpen={modal}
        toggle={modalToggle}
        backdrop={backdrop}
        size="lg"
        centered
      >
        
        <ModalHeader toggle={modalToggle}>
         {'Schedule Id: '} {itemDetails ? itemDetails.docId : ""}
        </ModalHeader>
        <ModalBody className="pt-1">
          {
            itemDetails ? 
              <>
                <Row>
                  <Col lg="2" sm="2">
                    Student: 
                  </Col>
                  <Col lg="10" sm="6">
                    {itemDetails.user.name}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col lg="2" sm="2">
                    Date: 
                  </Col>
                  <Col lg="10" sm="6">
                  
                    {moment.tz(itemDetails.starttime,"America/New_York").format('ll')}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col lg="2" sm="2">
                    Time: 
                  </Col>
                  <Col lg="10" sm="6">
                    {convertTimeToLocal(itemDetails.starttime).format("h:mm A") + ' - ' + convertTimeToLocal(itemDetails.endtime).format("h:mm A")}
                  </Col>
                </Row>
                <Row className="mt-2" >
                  <Col lg="2" sm="2">
                    Type: 
                  </Col>
                  <Col lg="10" sm="6">
                    {itemDetails.requestType.toUpperCase()}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col lg="2" sm="2">
                    Duration: 
                  </Col>
                  <Col lg="10" sm="6">
                    {itemDetails.duration}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col lg="2" sm="2">
                    Status: 
                  </Col>
                  <Col lg="10" sm="6">
                    
                      <Badge color="" className="badge-dot mr-4">
                      <i
                        className={
                          itemDetails.status == "pending"
                            ? "bg-info"
                            : itemDetails.status == "approved"
                            ? "bg-success"
                            : itemDetails.status == "disapproved"
                            ? "bg-danger"
                            : "bg-warning"
                        }
                      />
                    {itemDetails.status}
                    </Badge> 
                  </Col>
                </Row>
              </>
            : ""
          } 
        </ModalBody>
      
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
    fetchStudentRequestTutorHistory: state.fetchStudentRequestTutorHistory
  };
};

export default connect(mapStateToProps)(ManageStudentRequestHistory);
