import React, { Fragment,useState, useEffect,useMemo,useCallback } from "react";
import {
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardSubtitle,
  CardText,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  Pagination,
  FormGroup,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Button,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
ListGroup,
  ListGroupItem,
  Spinner,
  Alert,
  UncontrolledTooltip,
  Label,
  ButtonGroup
  
} from "reactstrap";
import  Select  from 'react-select';
import { customDropdown } from 'util/reactCustomStyles';
// import { Calendar,momentLocalizer, Views } from 'react-big-calendar'
import Header from "components/Headers/Header";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import classnames from "classnames";
import moment from 'moment-timezone';
// import 'moment-timezone'; 
import { customDropdownWithBorder } from "util/reactCustomStyles";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {  createRequestTutor,createRequestTutorReset,fetchCoaches,fetchAvailableSchedule,fetchSchedule  } from "redux/ScheduleTutor/ScheduleTutorActions";
import ScheduleItem from "components/ScheduleComponents/ScheduleItem";
import RequestsTable from "components/ScheduleComponents/RequestsTable";
import { buyTutoringHours } from "redux/Payments/PaymentsActions";
import BuyTutoringHoursModal from "components/Modal/BuyTutoringHoursModal";
import Plans from './Plans';
import { getUserData } from "redux/UserAuthentication/LoginActions";
import {
  fetchUserDetails,
} from "redux/ManageUsers/ManageUsersAction";
import { fetchLtpItem } from "redux/ManageLifeTimePlan/ManageLifeTimePlanActions";

import { Calendar, Badge as Badge2, List, HStack, Divider } from 'rsuite';
import 'rsuite/Calendar/styles/index.css';
import ReactDatetime from "react-datetime";
import { updateTutoringHoursAvailable } from "redux/Payments/PaymentsActions";
import dayjs from "dayjs";
import '../../assets/css/profile.css'

// moment.tz.setDefault("America/New_York");
moment.tz.setDefault(moment.tz.guess());
const ScheduleTutor = (props) => {

  const [userInfo,setUserInfo] = useState([]) 
  const [uastudent, setUaStudent ] = useState();

  useEffect(() => {
    // const localtz = moment.tz.guess()
    // const st = moment("2024-06-11T23:00:00Z")
    // const et = moment("2024-07-11T02:00:00Z")
    // const cv = st.tz('America/New_York').format('ha z')
    //  const cv2 = et.tz('America/New_York').format('ha z')
    // const cv = moment.tz("2024-06-11T19:00:00Z",true,'America/New_York').format("lll")
    // const cv2 = moment.tz("2024-06-11T21:00:00Z",true,'America/New_York').format("lll")
    

    setUserInfo(props.userDetails)
    
    if(props.userDetails.ultimateAdvisingStudent === true  ){
     
      setUaStudent(true)
    }
  }, [props.userDetails]);

  const [proMember, setProMember] = useState("")
	useEffect(() => {
    dispatch(fetchLtpItem())

    
    dispatch(fetchUserDetails({ userId: props.userDetails.userId }));

    
  }, [])

  const [pendingRequest30Minute, setPendingRequest30Minute] = useState(false)
  useEffect(()=> {
    

    if(props.fetchRequestTutor.fetchrequesttutor.length){
      setPendingRequest30Minute(props.fetchRequestTutor.fetchrequesttutor[0].some(item => item.codetype === "30FT" && item.status === "pending"));
    }

  },[props.fetchRequestTutor])
	
  useEffect(() => {
    if (props.fetchLtpItem.ltpitems.length) {
        props.fetchLtpItem.ltpitems.forEach((item)=>{
          if(props.userDetails.email == item.email){
            setProMember( item.email);
          }
      })  
    }
  }, [props.fetchLtpItem, props.userDetails]);
    

  const dispatch = useDispatch();
  const [events,setEvents] = useState([])
  const [selectedEvent, setSelectedEvent] = useState()

  // const customStyles = {
  //     menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  //     menu: (provided) => ({ ...provided, zIndex: 9999 }),
  // };

  const modalBuyTutoringHoursModal = props.buyTutoringHoursModalStatus.status;
  const toggleBuyTutoringHoursModal = () => {
    dispatch(buyTutoringHours(!props.buyTutoringHoursModalStatus.status));
  };


  //Tabs
  const mainopt = ["Coaches Schedule", "My Requests", "History"];
  const [mainActiveTab, setMainActiveTab] = useState("Coaches Schedule");

  const toggleMain = (tab) => {
    if (mainActiveTab !== tab) setMainActiveTab(tab);
  };

  const historyopt = ["Pending", "Approved", "Disapproved", "Canceled"];
  const [activeTab, setActiveTab] = useState("Pending");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [schedActiveTab, setSchedActiveTab] = useState("1");


  //Modal Behaviour
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [backdrop, setBackdrop] = useState(true);

  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
  const toggleNested = () => {
      setNestedModal(!nestedModal);
      setCloseAll(false);
  };

  const [coachDetailsModal, setCoachDetailsModal] = useState(false);
  const [coachData, setCoachData] = useState({});
  const toggleCoachDetails = (e, coachDatas = {}) => {
    e.preventDefault()
    
    setCoachData(coachDatas)
    setCoachDetailsModal(!coachDetailsModal);
    
  };
  
  const [scheduleTutorModal, setSchedulTutorModal] = useState(false);
  // const [coachAvailableScheduleData, setCoachAvailableScheduleData] = useState([]);

  const toggleScheduleTutorModal = (e, coachDatas = {}) => {
    // e.preventDefault()
    setCoachData(coachDatas)
    setSchedulTutorModal(!scheduleTutorModal);

    if(!scheduleTutorModal){
      
      onSelectEventFunction(moment().toDate().toString())
    }
    
    
    
  };




    //Set local timezone
    // const  myTimeZone  = Intl.DateTimeFormat().resolvedOptions();
    // moment.tz.setDefault(myTimeZone.timeZone)
    // moment.tz.setDefault("America/New_York")
    // const localizer = momentLocalizer(moment) 

    //Converts the datetime UTC to local time
    const convertToLocalTime = (date) => {
        return new Date(date)
    }

    // const {defaultDate, views} = useMemo(() => ({
    //     defaultDate: new Date(),
    //     // views: [Views.MONTH, Views.WEEK, Views.DAY ],
    //     views: [Views.MONTH],
    // }), [])

    const convertToTime = (time) =>{
        return moment(time).format('LT'); 
    }
    const convertToDate = (date) =>{
        
        return moment(date)._i; 
    }

    const handleSelectSlot = useCallback((slotinfo) => {
   
        window.prompt(JSON.stringify(slotinfo))
        
    }, [])


    function getNextDayArray(days) {
      const allDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    
      // Map days to indexes and sort
      let indexes = days.map(day => allDays.indexOf(day)).sort((a, b) => a - b);
    
      let expandedDays = [];
    
      for (let i = 0; i < indexes.length; i++) {
        const start = indexes[i];
        const end = indexes[i + 1] !== undefined ? indexes[i + 1] : (start + 1) % 7;
    
        let j = start;
        while (true) {
          const day = allDays[j];
          if (!expandedDays.includes(day)) {
            expandedDays.push(day);
          }
    
          if (j === end) break;
          j = (j + 1) % 7; // move forward with wrap
        }
      }
    
      return expandedDays;
    }
    


    function getPreviousDayArray(days) {
      const allDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    
      // Map days to indexes
      let indexes = days.map(day => allDays.indexOf(day)).sort((a, b) => a - b);
    
      let expandedDays = [];
    
      for (let i = 0; i < indexes.length; i++) {
        const end = indexes[i];
        const start = indexes[i - 1] !== undefined ? indexes[i - 1] : (end - 1 + 7) % 7;
    
        let j = start;
        while (true) {
          const day = allDays[j];
          if (!expandedDays.includes(day)) {
            expandedDays.push(day);
          }
    
          if (j === end) break;
          j = (j + 1) % 7; // move forward
        }
      }
    
      return expandedDays;
    }

    
    

    const onSelectEventFunction = (date) => {
     
      

      setSchedulePerMinute([])
      setSchedulePerHour([])


      // const parts = (date).toString().split(" ");
      // let formattedDate = moment(`${parts[1]} ${parts[2]}, ${parts[3]}`, 'MMM DD, YYYY').format('MMM D, YYYY');

      let formattedDate = moment(date).format('MMM D, YYYY');
  
      // let formattedDate = moment(date).format('MMM D, YYYY');
      


      setCoachData(prev => {





        setAvailableSchedule(prev2 => {
      
          let coachAvailableScheduleData = prev2.filter((schedule)=> schedule.userHandle == prev.handle).length ?  prev2.filter((schedule)=> schedule.userHandle == prev.handle) : [];

          coachAvailableScheduleData.sort((a, b) => moment(a.startdate, "MMM DD, YYYY").toDate() - moment(b.startdate, "MMM DD, YYYY").toDate());
          
          setUserInfo(userInfoData => {
            
            // if( userInfoData.tutorHours == "0"){
            //   toggleBuyTutoringHoursModal()    
            // }else{
              
              let dateSelect = moment(formattedDate,"MMM D, YYYY");

              
              if(coachAvailableScheduleData.find((data) => {
                
                const formattedStartDate = moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY")

                const formattedEndDate = moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).isAfter(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10))) ? moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).add(1,"days").format("MMM D, YYYY"),"MMM D, YYYY") :moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY") 
      
                // console.log(dateSelect.format("MMM D, YYYY"), moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"fucker1")
             

                data.formattedStartDate = formattedStartDate
                data.formattedEndDate = formattedEndDate
                
                



                let daysArr =   getNextDayArray(data.days)

                let dataDays = data.days

                let dayName = dayjs(dateSelect).format('dddd');

       
                if(moment(dateSelect.format("MMM D, YYYY"),"MMM D, YYYY").isAfter(moment(moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY"),"day")){
                  
                // if(formattedStartDate.isAfter(formattedEndDate)){

                  dataDays = getPreviousDayArray(dataDays).filter(day => !dataDays.includes(day))

                  daysArr =  getPreviousDayArray(dataDays)

                  dayName = moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("dddd")

                  

                }

                if(moment(dateSelect.format("MMM D, YYYY"),"MMM D, YYYY").isBefore(moment(moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY"),"day")){
                  
                // if(formattedStartDate.isBefore(formattedEndDate)){


                  dataDays = getNextDayArray(dataDays).filter(day => !dataDays.includes(day))
                  daysArr =  getNextDayArray(dataDays)

                  // dayName = moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("dddd")

                  
                  // console.log(moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)),"fucks")
                }

                
                data.daysArr = daysArr
                data.dataDays =  dataDays
                data.dayName =  dayName

                
                
                

                return formattedStartDate.isSame(formattedEndDate,"day") ? dataDays.includes(data.dayName) : daysArr.includes(data.dayName)
              
              })){

                


                let schedCoachAvailableScheduleData = coachAvailableScheduleData.filter((data) =>{ 
                  
                  
                  
                  // const formattedStartDate = moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY")

                  // const formattedEndDate = moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).isAfter(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10))) ? moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).add(1,"days").format("MMM D, YYYY"),"MMM D, YYYY") :moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY") 
           
                  // return formattedStartDate.isSame(formattedEndDate) ? data.days.includes(dayjs(dateSelect).format('dddd')) : getNextDayArray(data.days).includes(dayjs(dateSelect).format('dddd'))
                  
                  return data.formattedStartDate.isSame(data.formattedEndDate,"day") ? data.dataDays.includes(data.dayName) : data.daysArr.includes(data.dayName)
                
                })
                 
                
                

                schedCoachAvailableScheduleData.map(sched => {
                  
                  
                  
                  
                  if(moment(dateSelect.format("MMM D, YYYY"),"MMM D, YYYY").isAfter(moment(moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + sched.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY"),"day")){
                    dateSelect = sched.dataDays.includes(sched.dayName) ?  dateSelect.subtract(1,"days") : dateSelect
                  }
                  
                  if(moment(dateSelect.format("MMM D, YYYY"),"MMM D, YYYY").isBefore(moment(moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + sched.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY"),"day")){
                    
                    dateSelect = sched.dataDays.includes(sched.dayName) ? dateSelect : dateSelect.add(1,"days")
                  }

                  if(moment(dateSelect.format("MMM D, YYYY"),"MMM D, YYYY").isSame(moment(moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + sched.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY"),"day")){
                    
                    dateSelect = sched.dataDays.includes(sched.dayName) ? dateSelect : dateSelect.subtract(1,"days")
                  }
       
                  // let startDate = dateSelect.format("ll")
                  // let endDate = dateSelect.format("ll")
                  
                  let startDate = dateSelect
                  let endDate = dateSelect
                  let dates = [];
                  
                  
                  for (var m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
                
                   
                    
                    // if(sched.days.includes(m.format('dddd'))){
  
                        // let startTime = convertTimeToLocal(sched.starttime) 
                        // let endTime = convertTimeToLocal(sched.endtime) 

                        // let formattedStartDate = moment(sched.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + sched.starttime.slice(10)
                        // let formattedEndDate = moment(moment(sched.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + sched.starttime.slice(10)).isAfter(moment(moment(sched.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + sched.endtime.slice(10))) ? moment(sched.startdate, "MMM D, YYYY").add(1,"days").format("YYYY-MM-DD") + sched.endtime.slice(10) : moment(sched.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + sched.endtime.slice(10)
  
                        let startDateFormatted = m
                        let endDateFormatted = m
                    
                        // if(moment(endTime).format('HH:mm') < moment(startTime).format('HH:mm')){
                        //     endTime.add(1,'days')
                        //     endDateFormatted = moment(endDateFormatted).add(1,'days')
                            
                        // }
  
                        // let duration = moment.duration(endTime.diff(startTime));
                        // let diff = duration.hours();
                        // let getDAtes = [];
                        
                        if(moment(moment(m._i, "MMM D, YYYY").format('L'),'L').isSameOrAfter(moment(moment().format('L'),'L'))){
                          
                          
                          const start = moment(moment(startDateFormatted).format("MMM D, YYYY")+' '+convertTimeToLocal(sched.starttime).format('h:mm A'), "MMMM D, YYYY h:mm A")
                          let end = moment(moment(endDateFormatted).format("MMM D, YYYY")+' '+convertTimeToLocal(sched.endtime).format('h:mm A'), "MMMM D, YYYY h:mm A")

                          end = start.isAfter(end) ? end.add(1,'days') : end;
                          

                          
                          const eventDetails = {
                            scheduleid: sched.schedid,
                            day: moment(start).format("MMM D, YYYY"),
                            date: moment(start).format("MMM D, YYYY"),
                            title:convertTimeToLocal(sched.starttime).format('h:mm A')+' - '+convertTimeToLocal(sched.endtime).format('h:mm A') + " : " +sched.coach.name,
                            start: new Date(start.format("ddd MMM DD YYYY HH:mm:ss [GMT]Z [(]z[)]")),
                            end: new Date(end.format("ddd MMM DD YYYY HH:mm:ss [GMT]Z [(]z[)]")),

                            // start:new Date(moment(formattedStartDate).format("ddd MMM DD YYYY HH:mm:ss [GMT]Z [(]z[)]")),
                            // end:new Date(moment(formattedEndDate).format("ddd MMM DD YYYY HH:mm:ss [GMT]Z [(]z[)]")),
                            // startLabel:  moment(convertToDate(startDateFormatted)+' '+convertTimeToLocal(sched.starttime).format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
                            // endLabel: moment(convertToDate(endDateFormatted)+' '+convertTimeToLocal(sched.endtime).format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
                            dateSelected: moment(moment(date).format("MMM D, YYYY"),"MMM D, YYYY"),
                            // dateSelected:moment(moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + sched.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY"),
                            details: sched.coach,
                            requests: sched.requests,
                            color : getColorByLabel(sched.coach.name)[0],
                            description: sched.description
                          }

                    
                          
                          
                          setSelectedEvent(eventDetails)
                          
                          createAvailableTime(eventDetails)
  
                        }
  
                        
                        // for (let i = 0; diff >= i; i++) {
                        //      let result =  i == 0 ? moment(startTime).add(i, 'minutes') : moment(startTime).add(i, 'hours').subtract(30,'minutes')
                        //     getDAtes.push(
                        //         {
                        //             // id: sched.schedid+i,
                        //             desc: sched.description,
                        //             title: sched.coach.name,
                        //             details: sched.coach,
                        //             date: convertToDate(m),
                        //             start: new Date(convertToDate(m)+' '+result.format('h:mm A')),
                        //             end: new Date(convertToDate(m)+' '+moment(result).add(30,'minutes').format('h:mm A')),
                        //             startLabel: convertToDate(m)+' '+result.format('h:mm A'),
                        //             endLabel: convertToDate(m)+' '+moment(result).add(30,'minutes').format('h:mm A')
                        //         }
                        //     )
                        // }
                        
                        // dates.push({
                        //     day: convertToDate(m),
                        //     item : getDAtes
                        // })
                    // } 
                  }
                })
              }
              
              if(coachAvailableScheduleData.find((data) => {
                

                const formattedStartDate = moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY")

                const formattedEndDate = moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).isAfter(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10))) ? moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).add(1,"days").format("MMM D, YYYY"),"MMM D, YYYY") :moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY") 
                
                const targetDate  = moment(moment(date).format("MMM D, YYYY"),"MMM D, YYYY")
                
                
                return targetDate.isBetween(formattedStartDate,formattedEndDate, null, [])
              
              })){
                
                let schedCoachAvailableScheduleData = coachAvailableScheduleData.filter((data) => {
                  
                  data.formattedStartDate = moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)
                  data.formattedEndDate = moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).isAfter(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10))) ? moment(data.startdate, "MMM D, YYYY").add(1,"days").format("YYYY-MM-DD") + data.endtime.slice(10) : moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)
                  

                  

                  const formattedStartDate = moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY")

                  const formattedEndDate = moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).isAfter(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10))) ? moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).add(1,"days").format("MMM D, YYYY"),"MMM D, YYYY") :moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY") 
                  
                  const targetDate  = moment(moment(date).format("MMM D, YYYY"),"MMM D, YYYY")
                  
                  data.dateSelected = targetDate
                  

                  return targetDate.isBetween(formattedStartDate,formattedEndDate, null, [])
                
                })

                
                
  
                
                schedCoachAvailableScheduleData.map(sched => {

                
                  let startDate = sched.startdate
                  let endDate = sched.enddate
                  let dates = [];
                  
                  for (var m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
                    
                    // if(sched.days.includes(m.format('dddd'))){
  
                        // let startTime = convertTimeToLocal(sched.starttime) 
                        // let endTime = convertTimeToLocal(sched.endtime) 

                        let startTime = sched.formattedStartDate
                        let endTime = sched.formattedEndDate

                 
                        
                        

                        // let startDateFormatted = m
                        // let endDateFormatted = m

                        // if(moment(endTime).format('HH:mm') < moment(startTime).format('HH:mm')){
                        //   moment(endTime).add(1,'days')
                        //     endDateFormatted = moment(endDateFormatted).add(1,'days')
                            
                        // }
  
                        // let duration = moment.duration(moment(endTime).diff(moment(startTime)));
           
                        // let diff = duration.hours();
                        // let getDAtes = [];
                        
                       
                        if(moment(moment(m._i, "MMM D, YYYY").format('L')).isSameOrAfter(moment().format('L'))){
                          
                          
                          const eventDetails = {
                            scheduleid: sched.schedid,
                            // day: convertToDate(startDateFormatted),
                            // date: convertToDate(startDateFormatted),
                            day: moment(sched.formattedStartDate).format("MMM D, YYYY"),
                            date: moment(sched.formattedStartDate).format("MMM D, YYYY"),
                            title:convertTimeToLocal(sched.starttime).format('h:mm A')+' - '+convertTimeToLocal(sched.endtime).format('h:mm A') + " : " +sched.coach.name,
                            // start: moment(convertToDate(startDateFormatted)+' '+convertTimeToLocal(sched.starttime).format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
                            // end: moment(convertToDate(endDateFormatted)+' '+convertTimeToLocal(sched.endtime).format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
                            start: new Date(moment(sched.formattedStartDate).format("ddd MMM DD YYYY HH:mm:ss [GMT]Z [(]z[)]")),
                            end: new Date(moment(sched.formattedEndDate).format("ddd MMM DD YYYY HH:mm:ss [GMT]Z [(]z[)]")),
                            // startLabel:  moment(convertToDate(startDateFormatted)+' '+convertTimeToLocal(sched.starttime).format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
                            // endLabel: moment(convertToDate(endDateFormatted)+' '+convertTimeToLocal(sched.endtime).format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
                            dateSelected: sched.dateSelected,
                            details: sched.coach,
                            requests: sched.requests,
                            color : getColorByLabel(sched.coach.name)[0],
                            description: sched.description
                          }

                          
                          
                       
                          setSelectedEvent(eventDetails)
                          
                          

                          createAvailableTime(eventDetails)
  
                        }
  
                        
                        // for (let i = 0; diff >= i; i++) {
                        //      let result =  i == 0 ? moment(startTime).add(i, 'minutes') : moment(startTime).add(i, 'hours').subtract(30,'minutes')
                        //     getDAtes.push(
                        //         {
                        //             // id: sched.schedid+i,
                        //             desc: sched.description,
                        //             title: sched.coach.name,
                        //             details: sched.coach,
                        //             date: convertToDate(m),
                        //             start: new Date(convertToDate(m)+' '+result.format('h:mm A')),
                        //             end: new Date(convertToDate(m)+' '+moment(result).add(30,'minutes').format('h:mm A')),
                        //             startLabel: convertToDate(m)+' '+result.format('h:mm A'),
                        //             endLabel: convertToDate(m)+' '+moment(result).add(30,'minutes').format('h:mm A')
                        //         }
                        //     )
                        // }
                        
                        // dates.push({
                        //     day: convertToDate(m),
                        //     item : getDAtes
                        // })
                    // } 
                    
                  }
                })
                
              }
              // setModal(true)
            // }
            return userInfoData;
          })
          

          return prev2
        })
        // if(uastudent) {
          // if( userInfo.tutorHours == "0"){
          //   toggleBuyTutoringHoursModal()    
          // }else{
          //   setSelectedEvent(coachAvailableScheduleData.find((data) => data.startdate == formattedDate))
          //   // setModal(true)
          // }
        // }else{
          // Swal.fire({
          //   title: "Upgrade Your Account!",
          //   text: "Please subscribe to Ultimate Advising Account to book a schedule. Thank you!",
          //   icon: "info"
          // });
        // }

        return prev 
      })
      
      
      
    }

    

    function renderCellFilter(date, allSchedules, coaches) {
      
      let dateAvailable = false;
      return coaches
          .filter((coach) => {
            
            if(dateAvailable || moment(moment(date).format('MMM D, YYYY'),'MMM D, YYYY').isBefore(moment(),"day")){
              return false
            }
            // console.log(selectedDate,"fuck all")
            const formattedDate = moment(date).format('MMM D, YYYY')
            const dateSelect = moment(formattedDate,'MMM D, YYYY')
    
            // Find schedules matching the selected startdate
            
            const exactDateMatch = availableSchedule.some(
              (data) =>{
               if(data.userHandle === coach.handle ){
    
                const formattedStartDate = moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY")
    
                const formattedEndDate = moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).isAfter(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10))) ? moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).add(1,"days").format("MMM D, YYYY"),"MMM D, YYYY") :moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY") 
                    
                    const targetDate  = moment(moment(date).format("MMM D, YYYY"),"MMM D, YYYY")
                    
                    

                    if(targetDate.isBetween(formattedStartDate,formattedEndDate, null, [])){
                      dateAvailable = true
                      return true
                    }
                    else{
                      return false
                    }
                    return targetDate.isBetween(formattedStartDate,formattedEndDate, null, [])
               }
               return false 
              }
    
            );
    
    
            
            // Find schedules matching the selected day name if no exact date match is found
            // const fallbackDayMatch = !exactDateMatch && availableSchedule.some(
            //   (sched) =>
            //     sched.userHandle === coach.handle &&
            //     sched.days.includes(selectedDayName)
            // );
    
            
            
            const fallbackDayMatch = !exactDateMatch && availableSchedule.some((data) => {
              if(data.userHandle === coach.handle){
                const formattedStartDate = moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY")
    
                const formattedEndDate = moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).isAfter(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10))) ? moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).add(1,"days").format("MMM D, YYYY"),"MMM D, YYYY") :moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY") 
    
                // console.log(dateSelect.format("MMM D, YYYY"), moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"fucker1")
            
    
                data.formattedStartDate = formattedStartDate
                data.formattedEndDate = formattedEndDate
                
                
    
    
    
                let daysArr =   getNextDayArray(data.days)
    
                let dataDays = data.days
    
                let dayName = dayjs(dateSelect).format('dddd');
    
      
                if(moment(dateSelect.format("MMM D, YYYY"),"MMM D, YYYY").isAfter(moment(moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY"),"day")){
                  
                // if(formattedStartDate.isAfter(formattedEndDate)){
    
                  dataDays = getPreviousDayArray(dataDays).filter(day => !dataDays.includes(day))
    
                  daysArr =  getPreviousDayArray(dataDays)
    
                  dayName = moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("dddd")
    
                  
    
                }
    
                if(moment(dateSelect.format("MMM D, YYYY"),"MMM D, YYYY").isBefore(moment(moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY"),"day")){
                  
                // if(formattedStartDate.isBefore(formattedEndDate)){
    
    
                  dataDays = getNextDayArray(dataDays).filter(day => !dataDays.includes(day))
                  daysArr =  getNextDayArray(dataDays)
    
                  // dayName = moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("dddd")
    
                  
                  // console.log(moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)),"fucks")
                }
    
                
                data.daysArr = daysArr
                data.dataDays =  dataDays
                data.dayName =  dayName
    
                
                
              
                
                if(formattedStartDate.isSame(formattedEndDate,"day")){
                  

                  if(dataDays.includes(data.dayName)){
                    dateAvailable = true
                    return true
                  }
                  else{
                    return false
                  }
                }
                else{ 

                  if(daysArr.includes(data.dayName)){
                    dateAvailable = true
                    return true
                  }
                  else{
                    return false
                  }

                  
                }
                return  formattedStartDate.isSame(formattedEndDate,"day") ? dataDays.includes(data.dayName) : daysArr.includes(data.dayName)
              }
              return false
            
            })
    
            return exactDateMatch || fallbackDayMatch; // Use either exact date or fallback day
          }).length ? <i class="fas fa-circle text-info"></i>  : null 
        // return matchedEvents.length ? <i class="fas fa-circle text-info"></i> : null;
      
      
    }

    function renderCell(date, allSchedules, coachData) {
      // 1) Convert the incoming date to a consistent format, like "Mar 7, 2025"
      // const formattedDate = moment(`${parts[1]} ${parts[2]}, ${parts[3]}`, 'MMM DD, YYYY').format('MMM D, YYYY');
      
      const formattedDate = moment(date).format('MMM D, YYYY')
      
      // 2) Filter schedules for the current coach
      //    (Replace coachData.handle with your actual property if needed)
      const coachAvailableScheduleData = allSchedules.filter(
        (schedule) => schedule.userHandle === coachData.handle
      );
    
      // 3) Sort the filtered schedules by startdate
      coachAvailableScheduleData.sort(
        (a, b) =>
          moment(a.startdate, "MMM DD, YYYY").toDate() -
          moment(b.startdate, "MMM DD, YYYY").toDate()
      );
    
      // 4) The array in which we'll collect the final schedule blocks
      const matchedEvents = [];
    
      // 5) Convert the selected date to a moment (UTC offset set to 0, but adjust if needed)
      const dateSelect = moment(formattedDate, "MMM D, YYYY");
    
      // 6) Check if any schedule matches by weekday
      // const dayName = dayjs(dateSelect).format('dddd');
      const schedulesByDay = coachAvailableScheduleData.filter((data) => {
                
        const formattedStartDate = moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY")

        const formattedEndDate = moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).isAfter(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10))) ? moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).add(1,"days").format("MMM D, YYYY"),"MMM D, YYYY") :moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY") 

        // console.log(dateSelect.format("MMM D, YYYY"), moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"fucker1")
     

        data.formattedStartDate = formattedStartDate
        data.formattedEndDate = formattedEndDate
        
        



        let daysArr =   getNextDayArray(data.days)

        let dataDays = data.days

        let dayName = dayjs(dateSelect).format('dddd');


        if(moment(dateSelect.format("MMM D, YYYY"),"MMM D, YYYY").isAfter(moment(moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY"),"day")){
          
        // if(formattedStartDate.isAfter(formattedEndDate)){

          dataDays = getPreviousDayArray(dataDays).filter(day => !dataDays.includes(day))

          daysArr =  getPreviousDayArray(dataDays)

          dayName = moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("dddd")

          

        }

        if(moment(dateSelect.format("MMM D, YYYY"),"MMM D, YYYY").isBefore(moment(moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY"),"day")){
          
        // if(formattedStartDate.isBefore(formattedEndDate)){


          dataDays = getNextDayArray(dataDays).filter(day => !dataDays.includes(day))
          daysArr =  getNextDayArray(dataDays)

          // dayName = moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("dddd")

          
          // console.log(moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)),"fucks")
        }

        
        data.daysArr = daysArr
        data.dataDays =  dataDays
        data.dayName =  dayName

        
        
        

        return formattedStartDate.isSame(formattedEndDate,"day") ? dataDays.includes(data.dayName) : daysArr.includes(data.dayName)
      
      });
    
      if (schedulesByDay.length) {
        schedulesByDay.forEach((sched) => {
          // We handle a single-day range here, since you used dateSelect.format("ll") in your original code.
          let startDate = dateSelect; 
          let endDate = dateSelect;
    
          // For each day in [startDate, endDate] (though they are the same in this snippet)
          for (let m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
            let startTime = convertTimeToLocal(sched.starttime);
            let endTime = convertTimeToLocal(sched.endtime);
    
            // If endTime is earlier than startTime in 24-hour sense, it crosses midnight
            let endDateFormatted = m.clone();
            if (moment(endTime).format('HH:mm') < moment(startTime).format('HH:mm')) {
              endTime.add(1, 'days');
              endDateFormatted.add(1, 'days');
            }
    
            // Only if this date is today or in the future
            if (m.isSameOrAfter(moment().startOf('day'))) {
              const eventDetails = {
                scheduleid: sched.schedid,
                day: convertToDate(m),
                date: convertToDate(m),
                title: convertTimeToLocal(sched.starttime).format('h:mm A') +
                       ' - ' +
                       convertTimeToLocal(sched.endtime).format('h:mm A') +
                       ' : ' +
                       sched.coach.name,
                start: moment(convertToDate(m) + ' ' + startTime.format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
                end: moment(convertToDate(endDateFormatted) + ' ' + endTime.format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
                details: sched.coach,
                requests: sched.requests,
                color: getColorByLabel(sched.coach.name)[0],
                description: sched.description
              };
              // In your original code, you did setSelectedEvent(eventDetails) and createAvailableTime(eventDetails).
              // Here, we simply collect them in an array.
              matchedEvents.push(eventDetails);
            }
          }
        });
      }
      // 7) Else, if no schedule matches by weekday, check if schedules match by exact "startdate"
      if (coachAvailableScheduleData.find((data) => {
                

        const formattedStartDate = moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY")

        const formattedEndDate = moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).isAfter(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10))) ? moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).add(1,"days").format("MMM D, YYYY"),"MMM D, YYYY") :moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY") 
        
        const targetDate  = moment(moment(date).format("MMM D, YYYY"),"MMM D, YYYY")
        
        
        return targetDate.isBetween(formattedStartDate,formattedEndDate, null, [])
      
      })) {
        
        const schedulesByExactDate = coachAvailableScheduleData.filter((data) => {
                

          const formattedStartDate = moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY")

          const formattedEndDate = moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).isAfter(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10))) ? moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).add(1,"days").format("MMM D, YYYY"),"MMM D, YYYY") :moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY") 
          
          const targetDate  = moment(moment(date).format("MMM D, YYYY"),"MMM D, YYYY")
          
          
          return targetDate.isBetween(formattedStartDate,formattedEndDate, null, [])
        
        })
        
        schedulesByExactDate.forEach((sched) => {
          
          let startDate = sched.startdate; // e.g., "Mar 7, 2025"
          let endDate = sched.enddate;     // if it's the same day, or a range
          
          for (let m = moment(startDate); m.isSameOrBefore(endDate, "day"); m.add(1, 'days')) {
            let startTime = convertTimeToLocal(sched.starttime);
            let endTime = convertTimeToLocal(sched.endtime);
    
            let endDateFormatted = m.clone();
            if (moment(endTime).format('HH:mm') < moment(startTime).format('HH:mm')) {
              endTime.add(1, 'days');
              endDateFormatted.add(1, 'days');
            }
       

            
            // moment(moment(m._i, "MMM D, YYYY").format('L')).isSameOrAfter(moment().format('L'))
            // Only if this date is today or in the future
   
            if (m.isSameOrAfter(moment().startOf('day'))) {
 
              const eventDetails = {
                scheduleid: sched.schedid,
                day: convertToDate(m),
                date: convertToDate(m),
                title: convertTimeToLocal(sched.starttime).format('h:mm A') +
                       ' - ' +
                       convertTimeToLocal(sched.endtime).format('h:mm A') +
                       ' : ' +
                       sched.coach.name,
                start: moment(convertToDate(m) + ' ' + startTime.format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
                end: moment(convertToDate(endDateFormatted) + ' ' + endTime.format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
                details: sched.coach,
                requests: sched.requests,
                color: getColorByLabel(sched.coach.name)[0],
                description: sched.description
              };
              matchedEvents.push(eventDetails);
            }
          }
        });
      }
     
    
      // 8) Finally, return all the matched event details

      return matchedEvents.length ? <i class="fas fa-circle text-info"></i> : null;
    }

    const onSelectEvent = useCallback((date) => {
      
      
      onSelectEventFunction(date)
      

      
    }, [])

  const [coach, setCoach] = useState();


  

  const getRandomHexColor = () => {
    // Generate RGB values with a restricted range for semi-light to semi-dark colors
    const min = 70;  // Minimum brightness (higher = avoid very dark colors)
    const max = 200; // Maximum brightness (lower = avoid very light colors)
  
    const getChannelValue = () => Math.floor(Math.random() * (max - min + 1)) + min;
  
    const r = getChannelValue();
    const g = getChannelValue();
    const b = getChannelValue();
  
    // Convert to hex and format the result
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  };

	const [coachOption, setCoachOption] = useState([]);

  const [coaches, setCoaches] = useState([]);
    const selectACoach = (e) => {
		setCoach(e.value)
        if(e.value == ""){
          dispatch(fetchAvailableSchedule())
        }else{
          dispatch(fetchSchedule({handle: e.value}))
        }
	};

    useEffect(() => {
        if (props.fetchCoaches.fetchcoaches.length) {
            const coachesOption = props.fetchCoaches.fetchcoaches[0];
            coachesOption.unshift({ value: "", label: "All Coaches" });


            const updatedArray = coachesOption.map(item => ({
              ...item, // Spread the existing object properties
              color: getRandomHexColor() // Add a new color property
            }));

         
            
            setCoaches(props.fetchCoaches.fetchcoaches[0])
            setCoachOption(updatedArray);

        }
    }, [props.fetchCoaches]);

    useEffect(() => {
        dispatch( fetchAvailableSchedule());
        dispatch(fetchCoaches());
    }, []);

    const [initialSched, setInitialSched] = useState([])

    const convertThedate = (date) => {
      return moment(date).utcOffset(0, true).format('ll');
    }

    const getColorByLabel = (label) => {
      
      return coachOption
        .filter(event => event.label == label) // Find objects matching the name
        .map(event => event.color);          // Extract color property
    };

     const eventPropGetter = (event) => {
    return {
      style: {
        backgroundColor: event.color, // Use the color from event data
        color: "white",               // Ensure text is readable
        borderRadius: "5px",
        border: "none",
        padding: "5px",
      },
    };
  };
    

    const [availableSchedule, setAvailableSchedule] = useState([])
    useEffect(() => {
        if (props.fetchAvailableSchedule.loading) {
            Swal.fire({
                allowOutsideClick: false,
                didOpen: () => {
                Swal.showLoading();
                },
            });
        } else {
            Swal.close();
        }

        if (props.fetchAvailableSchedule.fetchavailableschedule.length) {
            // let iterateCollectionOfSched = []
            
            // props.fetchAvailableSchedule.fetchavailableschedule[0].map((sched, sindex)=>{
                
            //     let startDate = sched.startdate
            //     let endDate = sched.enddate
            //     let dates = [];
            //     for (var m = moment(startDate).add(1, 'days'); m.isSameOrBefore(moment(endDate).add(1, 'days')); m.add(1, 'days')) {
            //         // if(sched.days.includes(m.format('dddd'))){
                     
            //             let startDateFormatted = m
            //             let endDateFormatted = m

            //             let startTime = convertTimeToLocal(sched.starttime) 
            //             let endTime = convertTimeToLocal(sched.endtime) 

                        
                        
            //             if(moment(endTime).format('HH:mm') < moment(startTime).format('HH:mm')){
            //                 endTime.add(1,'days')
            //                 endDateFormatted = moment(endDateFormatted).add(1,'days')
            //             }

            //             let duration = moment.duration(endTime.diff(startTime));
            //             let diff = duration.hours();
            //             let getDAtes = [];

            //             if(moment(moment(m).format('L')).isSameOrAfter(moment().format('L'))){
                         
                          
            //               dates.push({
            //                   scheduleid: sched.schedid,
            //                   day: convertToDate(startDateFormatted),
            //                   date: convertToDate(startDateFormatted),
            //                   title: convertTimeToLocal(sched.starttime).format('h:mm A')+' - '+convertTimeToLocal(sched.endtime).format('h:mm A') + " : " + sched.coach.name,
            //                   start: moment(convertToDate(startDateFormatted)+' '+convertTimeToLocal(sched.starttime).format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
            //                   end: moment(convertToDate(endDateFormatted)+' '+convertTimeToLocal(sched.endtime).format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
            //                   startLabel:  moment(convertToDate(startDateFormatted)+' '+convertTimeToLocal(sched.starttime).format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
            //                   endLabel: moment(convertToDate(endDateFormatted)+' '+convertTimeToLocal(sched.endtime).format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
            //                   details: sched.coach,
            //                   requests: sched.requests,
            //                   color : getColorByLabel(sched.coach.name)[0]
                              
            //               })
            //               // moment(new Date(time)).utcOffset(0, true).format("h:mm A") 
            //             }

            //             // for (let i = 0; diff >= i; i++) {
            //             //     let result =  i == 0 ? moment(startTime).add(i, 'minutes') : moment(startTime).add(i, 'hours').subtract(30,'minutes')
            //             //     getDAtes.push(
            //             //         {
            //             //             // id: sched.schedid+i,
            //             //             desc: sched.description,
            //             //             title: sched.coach.name,
            //             //             details: sched.coach,
            //             //             date: convertToDate(m),
            //             //             start: new Date(convertToDate(m)+' '+result.format('h:mm A')),
            //             //             end: new Date(convertToDate(m)+' '+moment(result).add(30, 'minutes').format('h:mm A')),
            //             //             startLabel: convertToDate(m)+' '+result.format('h:mm A'),
            //             //             endLabel: convertToDate(m)+' '+moment(result).add(30, 'minutes').format('h:mm A')
            //             //         }
            //             //     )
            //             // }
                        
            //             // dates.push({
            //             //     day: convertToDate(m),
            //             //     item : getDAtes
            //             // })
            //         // } 
                    
            //     }
            //     iterateCollectionOfSched.push(dates)
              
            // })
            // setInitialSched(iterateCollectionOfSched)

            // let iterateCollectionOfSched = []
            
            setAvailableSchedule(props.fetchAvailableSchedule.fetchavailableschedule[0])
            // props.fetchAvailableSchedule.fetchavailableschedule[0].map((sched, sindex)=>{
                
            //     let startDate = sched.startdate
            //     let endDate = sched.enddate
            //     let dates = [];
            //     for (var m = moment(startDate).add(1, 'days'); m.isSameOrBefore(moment(endDate).add(1, 'days')); m.add(1, 'days')) {
            //         // if(sched.days.includes(m.format('dddd'))){
                     
            //             let startDateFormatted = m
            //             let endDateFormatted = m

            //             let startTime = convertTimeToLocal(sched.starttime) 
            //             let endTime = convertTimeToLocal(sched.endtime) 

                        
                        
            //             if(moment(endTime).format('HH:mm') < moment(startTime).format('HH:mm')){
            //                 endTime.add(1,'days')
            //                 endDateFormatted = moment(endDateFormatted).add(1,'days')
            //             }

            //             let duration = moment.duration(endTime.diff(startTime));
            //             let diff = duration.hours();
            //             let getDAtes = [];

            //             if(moment(moment(m).format('L')).isSameOrAfter(moment().format('L'))){
                         
                          
            //               dates.push({
            //                   scheduleid: sched.schedid,
            //                   day: convertToDate(startDateFormatted),
            //                   date: convertToDate(startDateFormatted),
            //                   title: convertTimeToLocal(sched.starttime).format('h:mm A')+' - '+convertTimeToLocal(sched.endtime).format('h:mm A') + " : " + sched.coach.name,
            //                   start: moment(convertToDate(startDateFormatted)+' '+convertTimeToLocal(sched.starttime).format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
            //                   end: moment(convertToDate(endDateFormatted)+' '+convertTimeToLocal(sched.endtime).format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
            //                   startLabel:  moment(convertToDate(startDateFormatted)+' '+convertTimeToLocal(sched.starttime).format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
            //                   endLabel: moment(convertToDate(endDateFormatted)+' '+convertTimeToLocal(sched.endtime).format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
            //                   details: sched.coach,
            //                   requests: sched.requests,
            //                   color : getColorByLabel(sched.coach.name)[0]
                              
            //               })
            //               // moment(new Date(time)).utcOffset(0, true).format("h:mm A") 
            //             }

            //             // for (let i = 0; diff >= i; i++) {
            //             //     let result =  i == 0 ? moment(startTime).add(i, 'minutes') : moment(startTime).add(i, 'hours').subtract(30,'minutes')
            //             //     getDAtes.push(
            //             //         {
            //             //             // id: sched.schedid+i,
            //             //             desc: sched.description,
            //             //             title: sched.coach.name,
            //             //             details: sched.coach,
            //             //             date: convertToDate(m),
            //             //             start: new Date(convertToDate(m)+' '+result.format('h:mm A')),
            //             //             end: new Date(convertToDate(m)+' '+moment(result).add(30, 'minutes').format('h:mm A')),
            //             //             startLabel: convertToDate(m)+' '+result.format('h:mm A'),
            //             //             endLabel: convertToDate(m)+' '+moment(result).add(30, 'minutes').format('h:mm A')
            //             //         }
            //             //     )
            //             // }
                        
            //             // dates.push({
            //             //     day: convertToDate(m),
            //             //     item : getDAtes
            //             // })
            //         // } 
                    
            //     }
            //     iterateCollectionOfSched.push(dates)
              
            // })
            // setInitialSched(iterateCollectionOfSched)
            setSelectedDate(new Date())

        }else{
          setInitialSched([])
        }
    }, [props.fetchAvailableSchedule]);

     useEffect(() => {
        if (props.fetchSchedule.loading) {
            Swal.fire({
                allowOutsideClick: false,
                didOpen: () => {
                Swal.showLoading();
                },
            });
        } else {
            Swal.close();
        }

        if (props.fetchSchedule.schedule.length) {
            let iterateCollectionOfSched = []
            props.fetchSchedule.schedule.map((sched, sindex)=>{
                let startDate = sched.startdate
                let endDate = sched.enddate
                let dates = [];
               
                for (var m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
                    if(sched.days.includes(m.format('dddd'))){
                       
                        let startTime = convertTimeToLocal(sched.starttime) 
                        let endTime = convertTimeToLocal(sched.endtime) 

                        let startDateFormatted = m
                        let endDateFormatted = m
                     
                        if(moment(endTime).format('HH:mm') < moment(startTime).format('HH:mm')){
                            endTime.add(1,'days')
                            endDateFormatted = moment(endDateFormatted).add(1,'days')
                            
                        }

                        let duration = moment.duration(endTime.diff(startTime));
                        let diff = duration.hours();
                        let getDAtes = [];

                        if(moment(moment(m).format('L')).isSameOrAfter(moment().format('L'))){
                          
                          dates.push({
                              scheduleid: sched.schedid,
                              day: convertToDate(startDateFormatted),
                              date: convertToDate(startDateFormatted),
                              title:convertTimeToLocal(sched.starttime).format('h:mm A')+' - '+convertTimeToLocal(sched.endtime).format('h:mm A') + " : " +sched.coach.name,
                              start: moment(convertToDate(startDateFormatted)+' '+convertTimeToLocal(sched.starttime).format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
                              end: moment(convertToDate(endDateFormatted)+' '+convertTimeToLocal(sched.endtime).format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
                              startLabel:  moment(convertToDate(startDateFormatted)+' '+convertTimeToLocal(sched.starttime).format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
                              endLabel: moment(convertToDate(endDateFormatted)+' '+convertTimeToLocal(sched.endtime).format('h:mm A'), "MMMM D, YYYY h:mm A").toDate(),
                              details: sched.coach,
                              requests: sched.requests,
                              color : getColorByLabel(sched.coach.name)[0]
                          })
                      }

                       
                        // for (let i = 0; diff >= i; i++) {
                        //      let result =  i == 0 ? moment(startTime).add(i, 'minutes') : moment(startTime).add(i, 'hours').subtract(30,'minutes')
                        //     getDAtes.push(
                        //         {
                        //             // id: sched.schedid+i,
                        //             desc: sched.description,
                        //             title: sched.coach.name,
                        //             details: sched.coach,
                        //             date: convertToDate(m),
                        //             start: new Date(convertToDate(m)+' '+result.format('h:mm A')),
                        //             end: new Date(convertToDate(m)+' '+moment(result).add(30,'minutes').format('h:mm A')),
                        //             startLabel: convertToDate(m)+' '+result.format('h:mm A'),
                        //             endLabel: convertToDate(m)+' '+moment(result).add(30,'minutes').format('h:mm A')
                        //         }
                        //     )
                        // }
                        
                        // dates.push({
                        //     day: convertToDate(m),
                        //     item : getDAtes
                        // })
                    } 
                    
                }

      
                iterateCollectionOfSched.push(dates)
              
            })

            setInitialSched(iterateCollectionOfSched)
        }else{
            setInitialSched([])
            // Swal.fire({
            //    title: "No schedule found!",
            //    text: "This coach has no schedule yet!",
            //    type: "warning"
            // });
            // setEvents([])
        }
        
    }, [props.fetchSchedule]);

    useEffect(() => {
        if(initialSched.length){
           
            let collectEvents = []
            initialSched.map((coaches, ndx) => {
                coaches.map((items, i) => {
                    // items.map((scheds, index) => {
                        collectEvents.push(items)
                    // })
                })
                
            })
            setEvents(collectEvents)
        }else{
            setEvents([])
        }
    }, [initialSched]);

    const [selectedType, selectType] = useState("1HPT");
    const requestType = [
        {
            label: '1hr DAT Premium Hour',
            value: '1HPT',
        },
        // {
        //     label: '30min DAT Tutoring',
        //     value: '30MPT',
        // },
        {
            label: '30min Admission Assistance',
            value: '30FT',
        },
    ] 
    
    const handleSelectType = (e,type) => {
      selectType(type)
    }
    // useEffect(() => {
      // if(selectedEvent){
        
    const createAvailableTime = (selectedEvent) => {


        // let startTime = moment(moment(selectedEvent.start), "HH:mm")
        // let endTime = moment(moment(selectedEvent.end), "HH:mm")

        let startTime = moment(selectedEvent.start, "HH:mm")
        let endTime = moment(selectedEvent.end, "HH:mm")

        let duration = moment.duration(endTime.diff(startTime));
        let diff = duration.hours() == 0?24 : duration.hours() ;
        let getSchedPerHour = [];
        let getSchedPerMinute = [];

        
        
        //Per Hour
      
        // for (let i = 0; diff > i; i++) {
        //     let result =  i == 0 ? moment(startTime) : moment(startTime).add(i, 'hours')
            

            
        //     if(moment(result.format("MMM D, YYYY"),"MMM D, YYYY").isSame(selectedEvent.dateSelected)){
        //       getSchedPerHour.push(
        //         {
        //           coachSchedId: selectedEvent.scheduleid, 
        //           start: result.format('LLL'),
        //           end: moment(result).add(1,'hours').format('LLL'),
        //         }
        //       )
        //     }
            
        // }
        
        for (let i = startTime; i < endTime; i.add(1,"hours")) {
          // console.log(i.format("MMM D, YYYY"),selectedEvent.dateSelected.format("MMM D, YYYY"),"fuckkks")
          if(moment(i.format("MMM D, YYYY"),"MMM D, YYYY").isSame(selectedEvent.dateSelected)){
            
            getSchedPerHour.push(
              {
                coachSchedId: selectedEvent.scheduleid, 
                start: i.format('LLL'),
                end: moment(i).add(1,'hours').format('LLL'),
              }
            )
          }

        }
        


        
        

        // let duration30 = moment.duration(endTime.diff(startTime));
        // let diff30 = Math.ceil(duration30.asMinutes() / 30); // Convert total minutes into 30-minute slots
        

        // Per 30 Minutes
        // for (let i = 0; i < diff30; i++) {
        //     let result = i === 0 ? moment(startTime) : moment(startTime).add(i * 30, 'minutes');
        //     if(moment(result.format("MMM D, YYYY"),"MMM D, YYYY").isSame(selectedEvent.dateSelected)){
        //       getSchedPerMinute.push({
        //           coachSchedId: selectedEvent.scheduleid, 
        //           start: result.format('LLL'),
        //           end: moment(result).add(30, 'minutes').format('LLL'),
        //       });
        //     }
        // }

        startTime = moment(selectedEvent.start, "HH:mm")
        endTime = moment(selectedEvent.end, "HH:mm")

        for (let x = startTime; x < endTime; x.add(30,"minutes")) {
          
          if(moment(x.format("MMM D, YYYY"),"MMM D, YYYY").isSame(selectedEvent.dateSelected)){
            
            getSchedPerMinute.push(
              {
                coachSchedId: selectedEvent.scheduleid, 
                start: x.format('LLL'),
                end: moment(x).add(30,'minutes').format('LLL'),
              }
            )
          }

        }

        


        //Per 30min
        // startTime.add(1,'hours')  
        // endTime.add(1,'hours') 
        
        // while (startTime < endTime) {
        //     getSchedPerMinute.push(
        //         {
        //           coachSchedId: selectedEvent.scheduleid, 
        //           start: startTime.format('LLL'),
        //           end: startTime.add(30,'minutes').format('LLL'), 
        //         }
        //     )
            
        // }
        // startTime.add(30,'minutes')
        // getSchedPerMinute.push(
        //   {
        //     coachSchedId: selectedEvent.scheduleid, 
        //     start: startTime.format('LLL'),
        //     end: startTime.add(30,'minutes').format('LLL'), 
        //   }
        // )

        let filterPerHour = []
        
        getSchedPerHour.map((hsched, i) => {
            
            if(selectedEvent.requests.length){
                selectedEvent.requests.forEach(request => {
                  
                if(hsched.coachSchedId == request.coachScheduleID){
                  
                 
                    if((convertTimeToUTC(hsched.start).format() == request.starttime || convertTimeToUTC(hsched.end).format() == request.endtime ) && request.status != "canceled"){
                      
                      hsched.status = "unavailable"
                     
                    } 
                    
                }
                
                });
                
                filterPerHour.push(hsched)
            }else{
                filterPerHour.push(hsched)
            }
        })

        
        
        
        let filterPerMinute = []

        getSchedPerMinute.map((msched, im) => {
            if(selectedEvent.requests.length){
                
                selectedEvent.requests.forEach(request => {
                    if(msched.coachSchedId == request.coachScheduleID){
                        if((convertTimeToUTC(msched.start).format() == request.starttime || convertTimeToUTC(msched.end).format() == request.endtime) && request.status != "canceled"){

                          msched.status = "unavailable"

                        }
                       
                    }
                });
                
                
                filterPerMinute.push(msched)
            }else{
                
                filterPerMinute.push(msched)
            }
        })
        //  for (let i = 0; diff >= i; i++) {
        //     let result =  i == 0 ? moment(startTime).add(i, 'minutes') : moment(startTime).add(i, 'hours').subtract(30,'minutes')

          
        //     getSchedPerMinute.push(
        //         {
        //             start: result.format('LLL'),
        //             end: moment(result).add(30,'minutes').format('LLL'), 
        //         }
        //     )
        // }

  
        // setSchedulePerHour(filterPerHour)
        
        // setSchedulePerHour(prevSchedulePerHour =>{// Merge previous and new events
        //   let mergedEvents = [...prevSchedulePerHour, ...filterPerHour];
        
        //   // Remove duplicates based on `startdate`, keeping only the latest occurrence
        //   let uniqueEvents = Array.from(
        //     new Map(mergedEvents.map(event => [event.start, event])).values()
        //   );

        //   uniqueEvents.sort((a, b) => moment(a.startdate, "MMMM D, YYYY h:mm A").valueOf() - moment(b.startdate, "MMMM D, YYYY h:mm A").valueOf());

        //   return uniqueEvents;
        // });

        // setSchedulePerMinute(prevSchedulePerMinute => {// Merge previous and new events
        //   let mergedEvents = [...prevSchedulePerMinute, ...filterPerMinute];
        
        //   // Remove duplicates based on `startdate`, keeping only the latest occurrence
        //   let uniqueEvents = Array.from(
        //     new Map(mergedEvents.map(event => [event.start, event])).values()
        //   );

        //   uniqueEvents.sort((a, b) => moment(a.startdate, "MMMM D, YYYY h:mm A").valueOf() - moment(b.startdate, "MMMM D, YYYY h:mm A").valueOf());

        //   return uniqueEvents;
        // });

        // const now = moment().tz("America/New_York"); // Get current time in New York timezone
        const twoHoursFromNow = moment().clone().add(1, "hours");
        
        setSchedulePerHour(prevSchedulePerHour => {
          // Merge previous and new events
          let mergedEvents = [...prevSchedulePerHour, ...filterPerHour];

          // Remove duplicates based on `startdate`, keeping only the latest occurrence
          let uniqueEvents = Array.from(
            new Map(mergedEvents.map(event => [event.start, event])).values()
          );

          // **Keep only events that are at least 2 hours in the future**
          uniqueEvents = uniqueEvents.filter(event => 
            moment(event.start, "MMM D, YYYY h:mm A").isSameOrAfter(twoHoursFromNow)
          );

          // Sort by `start`
          uniqueEvents.sort((a, b) => 
            moment(a.start, "MMM D, YYYY h:mm A").valueOf() - 
            moment(b.start, "MMM D, YYYY h:mm A").valueOf()
          );

          
          return uniqueEvents;
        });

        setSchedulePerMinute(prevSchedulePerMinute => {
          // Merge previous and new events
          let mergedEvents = [...prevSchedulePerMinute, ...filterPerMinute];

          // Remove duplicates based on `startdate`, keeping only the latest occurrence
          let uniqueEvents = Array.from(
            new Map(mergedEvents.map(event => [event.start, event])).values()
          );
          
          // **Keep only events that are at least 2 hours in the future**
          uniqueEvents = uniqueEvents.filter(event => 
            moment(event.start, "MMM D, YYYY h:mm A").isSameOrAfter(twoHoursFromNow)
          );
          
          // Sort by `start`
          uniqueEvents.sort((a, b) => 
            moment(a.start, "MMM D, YYYY h:mm A").valueOf() - 
            moment(b.start, "MMM D, YYYY h:mm A").valueOf()
          );
          
          return uniqueEvents;
        });


        // setSchedulePerMinute(filterPerMinute)
      
      // }
    }
    // }, [selectedEvent]);

    // const [studentRequests, setStudentRequests] = useState([])
    // useEffect(() => {
    //   if(props.fetchStudentSchedule.fetchstudentschedule.length){
    //     setStudentRequests(props.fetchStudentSchedule.fetchstudentschedule)
    //   }
    
    // }, []);

    const [schedulePerHour,setSchedulePerHour] = useState([])
    const [schedulePerMinute,setSchedulePerMinute] = useState([])
    const [selectedSchedule,setSelectedSchedule ] = useState()

    const convertTimeToUTC = (time) => {
        return moment(moment(time, "MMMM D, YYYY h:mm A").utcOffset(0, false), "HH:mm")
    }

    const convertTimeToLocal = (time) => {
        return moment(moment(time).utcOffset(0, true), "HH:mm")
    }

    const handleSelectSchedule = (data) =>{
       
        setNestedModal(!nestedModal);
        setSelectedSchedule(data)
    }

    const [sessionDescription, setSessionDescription] = useState("")

    const handleRequestTutorOnClick = (e) => {
        e.preventDefault();


        if(selectedType == "1HPT"){
          if(parseInt(userInfo.tutorHours) <= 0){
            toggleBuyTutoringHoursModal()
            return false
          }
        }

        Swal.fire({
            allowOutsideClick : false,
            didOpen: () => {
              Swal.showLoading()
            }
        });
        setNestedModal(!nestedModal);
        setModal(!modal)
        
        const doc = {
            starttime: convertTimeToUTC(selectedSchedule.start).format(),
            endtime: convertTimeToUTC(selectedSchedule.end).format(),
            status: "pending",
            codetype: selectedType,
            category: "schedule tutor",
            requestType: selectedType == "30FT" ? "free" : "premium", 
            duration: selectedType == "1HPT" ? "1hr" : "30min",
            studentHandle: userInfo.handle,
            coachScheduleID: selectedEvent.scheduleid,
            coachHandle: selectedEvent.details.handle,
            sessionDescription: sessionDescription,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
        };

        const coachDetails = {
          coachEmail : selectedEvent.details.email,
          coachName : selectedEvent.details.name,
          date:   moment(selectedSchedule.start, "MMMM D, YYYY h:mm A").format("ll") != moment(selectedSchedule.end, "MMMM D, YYYY h:mm A").format("ll") ? moment(selectedSchedule.start, "MMMM D, YYYY h:mm A").format("ll")+" - "+moment(selectedSchedule.end, "MMMM D, YYYY h:mm A").format("ll"):  moment(selectedSchedule.start, "MMMM D, YYYY h:mm A").format("ll"),
          time:  moment(selectedSchedule.start, "MMMM D, YYYY h:mm A").format('h:mm A')+ " - "+ moment(selectedSchedule.end, "MMMM D, YYYY h:mm A").format('h:mm A') 
        }


        dispatch(createRequestTutor({ handle: userInfo, docs: doc, coach: coachDetails }));
    };

    useEffect(() => {
      
      // if(props.createRequestTutor.loading){
      //   Swal.fire({
      //       allowOutsideClick : false,
      //       didOpen: () => {
      //           Swal.showLoading()
      //       }
      //   });
      // }
      // else{
      //     Swal.close()
      // }


      if(props.createRequestTutor.createrequesttutor.length){
      
        
        Swal.fire({
            title: "Success",
            text: "Successfully submitted a request!",
            icon: "success",
            timer: 3000,
        }).finally(() => {
            setSchedulTutorModal(false)
            setSessionDescription("")
            dispatch(fetchAvailableSchedule())
            dispatch(fetchUserDetails({ userId: userInfo.userId }));
          
        })

        
        

      } 
      else if (typeof props.createRequestTutor.error !== "undefined") {
        Swal.fire({
          title: "Failed!",
          text: "Failed to submit request",
          icon: "error",
          timer: 3000,
        }).finally(() => {
            setSchedulTutorModal(false)
            setSessionDescription("")
            dispatch(fetchAvailableSchedule())
            dispatch(fetchUserDetails({ userId: userInfo.userId }));
          

        });
      }

    }, [props.createRequestTutor])

    useEffect(() => {

      if(props.fetchUserDetails.userdetails.length){
        
        setUserInfo(props.fetchUserDetails.userdetails[0])
      }
    }, [props.fetchUserDetails])

    

    const formatPhoneNumber = (phoneNumberString) => {
      if (!phoneNumberString) return null;
    
      // Remove all non-digit characters
      let cleaned = phoneNumberString.replace(/\D/g, '');
    
      // Handle different lengths (US format)
      if (cleaned.length === 10) {
        return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
      } else if (cleaned.length === 11 && cleaned.startsWith("1")) {
        // Handle US numbers with country code (e.g., 1XXXXXXXXXX)
        return `+1 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
      } else {
        return cleaned; // Return the raw digits if format is unknown
      }
    };

    const [activeTutorTime, setActiveTutorTime] = useState("60Minute")

    const handleSwitchTutorTime = (data) => {

      if(data == "30Minute"){
        selectType("30FT")
      }
      else{
        selectType("1HPT")
      }

      setActiveTutorTime(data)
    }
  
  let twoDaysBefore = moment();
  let valid = function (current) {
   
      return current.startOf('day').isSameOrAfter(twoDaysBefore.startOf('day'));
  };

  // const [coachQuery, setCoachQuery] = useState("");

  // const filteredData = coaches.filter(item =>
  
  //   (item.name && item.name.toLowerCase().includes(coachQuery.toLowerCase())) ||
  //   (item.coachTitle && item.coachTitle.toLowerCase().includes(coachQuery.toLowerCase())) ||
  //   (item.coachPosition && item.coachPosition.toLowerCase().includes(coachQuery.toLowerCase()))
  // );

  // const handleSearchAvailableCoach = (e) => {
  //   // const startDate = e.target.value;
  //     // setEndDateValid(e);
  //     // setStartDate(new Date(e.toJSON()));
 
  //   // return false;
  //   if(e){

    
  //     const selectedDate = moment(new Date(e.toJSON()),"MMMM D, YYYY h:mm A").format('ll'); // Selected date for filtering

  //     const filteredAndSortedUsers = [...coaches]
  //       .filter(u => {
  //         // Get all schedules matching the user's email
  //         let matchingSchedules = availableSchedule.filter(sched => sched.userHandle === u.handle);

  //         // Filter schedules where startdate is on or after the selected date
  //         let validSchedules = matchingSchedules.filter(sched => 
  //           moment(sched.startdate, "MMM D, YYYY").isSame(moment(selectedDate, "MMM D, YYYY"))
  //         );

  //         // Keep the user if they have at least one valid schedule
  //         return validSchedules.length > 0;
  //       })
  //       .sort((a, b) => {
  //         // Find the earliest startdate for each user
  //         let startA = availableSchedule
  //           .filter(sched => sched.userHandle === a.handle)
  //           .filter(sched => moment(sched.startdate, "MMM D, YYYY").isSame(moment(selectedDate, "MMM D, YYYY")))
  //           .map(sched => moment(sched.startdate, "MMM D, YYYY").valueOf())
  //           .sort((x, y) => x - y)[0]; // Get the earliest valid startdate

  //         let startB = availableSchedule
  //           .filter(sched => sched.userHandle === b.handle)
  //           .filter(sched => moment(sched.startdate, "MMM D, YYYY").isSame(moment(selectedDate, "MMM D, YYYY")))
  //           .map(sched => moment(sched.startdate, "MMM D, YYYY").valueOf())
  //           .sort((x, y) => x - y)[0]; // Get the earliest valid startdate

  //         // Handle cases where users don't have a valid startdate
  //         if (!startA) return 1;
  //         if (!startB) return -1;

  //         return startA - startB;
  //       });

 
  //   }
  // }

  const [coachQuery, setCoachQuery] = useState("");
const [selectedDate, setSelectedDate] = useState(null);
const [selectedDayName, setSelectedDayName] = useState(null);

const handleSearchAvailableCoach = (e) => {
  if (typeof e !== "string") {
    const formattedDate = e; // Format selected date
    const dayName = moment(new Date(e.toJSON())).format("dddd"); // Extract the day name
    
    // return false 
    // console.log(formattedDate,"fuck")
    setSelectedDate(formattedDate);
    setSelectedDayName(dayName);
  } else {
    setSelectedDate(null);
    setSelectedDayName(null);
  }
};

const filteredData = useMemo(() => {
  console.log(selectedDate,"fuck")
  console.log(new Date(),"fuck")
  return selectedDate
  ? coaches
      .filter((coach) => {
        if(moment(moment(selectedDate).format('MMM D, YYYY'),'MMM D, YYYY').isBefore(moment(),"day")){
          return false
        }
        // console.log(selectedDate,"fuck all")
        const formattedDate = moment(selectedDate).format('MMM D, YYYY')
        const dateSelect = moment(formattedDate,'MMM D, YYYY')

        // Find schedules matching the selected startdate
        const exactDateMatch = availableSchedule.some(
          (data) =>{
           if(data.userHandle === coach.handle ){

            const formattedStartDate = moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY")

            const formattedEndDate = moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).isAfter(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10))) ? moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).add(1,"days").format("MMM D, YYYY"),"MMM D, YYYY") :moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY") 
                
                const targetDate  = moment(moment(selectedDate).format("MMM D, YYYY"),"MMM D, YYYY")
                
                
                return targetDate.isBetween(formattedStartDate,formattedEndDate, null, [])
           }
           return false 
          }

        );


        
        // Find schedules matching the selected day name if no exact date match is found
        // const fallbackDayMatch = !exactDateMatch && availableSchedule.some(
        //   (sched) =>
        //     sched.userHandle === coach.handle &&
        //     sched.days.includes(selectedDayName)
        // );

        
        
        const fallbackDayMatch = !exactDateMatch && availableSchedule.some((data) => {
          if(data.userHandle === coach.handle){
            const formattedStartDate = moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY")

            const formattedEndDate = moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).isAfter(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10))) ? moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).add(1,"days").format("MMM D, YYYY"),"MMM D, YYYY") :moment(moment(moment(data.startdate, "MMM D, YYYY").format("YYYY-MM-DD") + data.endtime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY") 

            // console.log(dateSelect.format("MMM D, YYYY"), moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"fucker1")
        

            data.formattedStartDate = formattedStartDate
            data.formattedEndDate = formattedEndDate
            
            



            let daysArr =   getNextDayArray(data.days)

            let dataDays = data.days

            let dayName = dayjs(dateSelect).format('dddd');

  
            if(moment(dateSelect.format("MMM D, YYYY"),"MMM D, YYYY").isAfter(moment(moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY"),"day")){
              
            // if(formattedStartDate.isAfter(formattedEndDate)){

              dataDays = getPreviousDayArray(dataDays).filter(day => !dataDays.includes(day))

              daysArr =  getPreviousDayArray(dataDays)

              dayName = moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("dddd")

              

            }

            if(moment(dateSelect.format("MMM D, YYYY"),"MMM D, YYYY").isBefore(moment(moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("MMM D, YYYY"),"MMM D, YYYY"),"day")){
              
            // if(formattedStartDate.isBefore(formattedEndDate)){


              dataDays = getNextDayArray(dataDays).filter(day => !dataDays.includes(day))
              daysArr =  getNextDayArray(dataDays)

              // dayName = moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)).format("dddd")

              
              // console.log(moment(moment(formattedDate, "MMM D, YYYY").format("YYYY-MM-DD") + data.starttime.slice(10)),"fucks")
            }

            
            data.daysArr = daysArr
            data.dataDays =  dataDays
            data.dayName =  dayName

            
            
          
            
            return  formattedStartDate.isSame(formattedEndDate,"day") ? dataDays.includes(data.dayName) : daysArr.includes(data.dayName)
          }
          return false
        
        })

        return exactDateMatch || fallbackDayMatch; // Use either exact date or fallback day
      })
      .filter((coach) => {
        // Search Filter (Only applied if a date is selected)
        return (
          (coach.name && coach.name.toLowerCase().includes(coachQuery.toLowerCase())) ||
          (coach.coachTitle && coach.coachTitle.toLowerCase().includes(coachQuery.toLowerCase())) ||
          (coach.coachPosition && coach.coachPosition.toLowerCase().includes(coachQuery.toLowerCase()))
        );
      })
      .sort((a, b) => {
        let startA = availableSchedule
          .filter((sched) => sched.userHandle === a.handle)
          .filter((sched) =>
            moment(sched.startdate, "MMM D, YYYY").isSame(moment(selectedDate, "MMM D, YYYY")) ||
            sched.days.includes(selectedDayName)
          )
          .map((sched) => moment(sched.startdate, "MMM D, YYYY").valueOf())
          .sort((x, y) => x - y)[0];

        let startB = availableSchedule
          .filter((sched) => sched.userHandle === b.handle)
          .filter((sched) =>
            moment(sched.startdate, "MMM D, YYYY").isSame(moment(selectedDate, "MMM D, YYYY")) ||
            sched.days.includes(selectedDayName)
          )
          .map((sched) => moment(sched.startdate, "MMM D, YYYY").valueOf())
          .sort((x, y) => x - y)[0];

        if (!startA) return 1;
        if (!startB) return -1;

        return startA - startB;
      })
  : coaches.filter((item) =>
      (item.name && item.name.toLowerCase().includes(coachQuery.toLowerCase())) ||
      (item.coachTitle && item.coachTitle.toLowerCase().includes(coachQuery.toLowerCase())) ||
      (item.coachPosition && item.coachPosition.toLowerCase().includes(coachQuery.toLowerCase()))
    );

  }, [selectedDate,coaches]);

  
  
  useEffect(() => {

    if(props.updateTutoringHoursAvailable.updatetutoringhoursavailable.length){
      dispatch(fetchUserDetails({ userId: userInfo.userId }));
    }

  }, [props.updateTutoringHoursAvailable])


  
  useEffect(() => {

    if(props.cancelRequestTutor.cancelrequesttutor.length){
      dispatch(fetchAvailableSchedule());
    }
  }, [props.cancelRequestTutor])
  

  const Styles = () => {
    return <style>{`.bg-gray { background-color: rgba(242, 242, 242, 0.9);}`}</style>;
  };
  
  return (
    <>
      <Header></Header>

      <Container className="mt--7" fluid>
        <Row>
          <Col className="mt-3 mb-4 d-flex justify-content-center">
            <Nav fill pills>
              {mainopt.length > 0
                ? mainopt.map((c, i) => {
                    return (
                      <NavItem className="ml-1" key={i}>
                        <NavLink
                          key={c}
                          className={classnames({
                            active: mainActiveTab === c,
                          })}
                          onClick={() => {
                            toggleMain(c);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {c}
                        </NavLink>
                      </NavItem>
                    );
                  })
                : ""}
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="mt-3 p-2">
              <TabContent activeTab={mainActiveTab}>
                 {mainopt.map((mc, i) => (
                  <TabPane tabId={mc} key={i}>
                    {
                    mc == "Coaches Schedule" ? 
                      <Row className="mt-4">
                        <Col lg="12" >
                          <Card className="shadow">
                              <CardHeader className="border-1">
                                  <Row>
                                    
                                    {/* <Col log="6"> */}
                                      {/* <h5 className="h3 mb-0 text-yellow">
                                        
                                        {
                                          // uastudent ? 
                                            userInfo.tutorHours == "0" || userInfo.tutorHours == 0
                                            ? 
                                            <>
                                              <Button color="success" onClick={toggleBuyTutoringHoursModal} className="mr-2 " >Buy DAT Premium Hours</Button> 
                                              <p>You have 0 DAT Premium Hours Remaining</p>
                                            </>
                                            : userInfo.tutorHours + ' Hours DAT Premium' 
                                          // : ""
                                        }
                                      </h5> */}
                                      {/* {
                                        uastudent ?  */}
                                        {/* <h5 className="h3 mb-0 text-yellow">  
                                        30 minutes Admission Assistance Per Week <span className="fa fa-question-circle text-default" id={"freeinfo"} role="button" ></span>
                                          <UncontrolledTooltip placement="right" target={"freeinfo"}>
                                            Only choose the 30-minute bonus session if it has already been
                                              approved. These sessions should be reserved for quarterly
                                              check-ins or for general coaching when you have exhausted your
                                              premium hours.
                                          </UncontrolledTooltip>
                                      </h5> */}
                                      {/* //   : '' */}
                                      {/* // } */}

                                    {/* </Col> */}
                                      
                                      <Col lg="6" className="">
                                        <FormGroup>
                                          <Label >Date of Desired Session : </Label>
                                          <h2 className=" font-weight-bolder text-warning mb-4 ml-3"><i class="fas fa-clock"></i> Local Timezone - {moment.tz.guess()}</h2>
                                          {/* <ReactDatetime
                                            onChange={(e) => handleSearchAvailableCoach(e)}
                                            isValidDate={valid}
                                            timeFormat={false}
                                            className="w-100"
                                            
                                            inputProps={{ placeholder: "Select Date"}}
                                          /> */}

                                          <Calendar compact bordered onSelect={handleSearchAvailableCoach} renderCell={(date)=>renderCellFilter(date, availableSchedule, coaches)} style={{ width: "100%", fontWeight:"bolder"}}  cellClassName={date => moment(moment(date).format('MMM D, YYYY'),'MMM D, YYYY').isBefore(moment(),"day") ? 'custom-height-cell custom-height-cell-disabled bg-light' : 'custom-height-cell'} value={selectedDate}/>
                                            
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6"></Col>

                                      <Col lg="6">
                                        <FormGroup>
                                          <Label >Search Coach : </Label>
                                          <Input 
                                            type="text" 
                                            className="" 
                                            name="search" 
                                            id="search" 
                                            placeholder="Search by name, position, or title" 
                                            onChange={(e) => setCoachQuery(e.target.value)}
                                          
                                          />
                                        
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6"></Col>
                                      

                                      
                                        
                                      { props.fetchRequestTutorHistory.fetchrequesttutorhistory.length  ? props.fetchRequestTutorHistory.fetchrequesttutorhistory[0].length == 0 && (userInfo.introductoryCall == false || typeof userInfo.introductoryCall == "undefined") && (userInfo.ultimateAdvisingStudent === true || userInfo.ultimateAdvisingStudent === "true" ) == true ?  

                                        <><Col lg="6" className="mt-3">
                                          <h2 className="text-info">Just upgraded to Ultimate Advising Student?</h2>
                                          <p><strong>Schedule a 1:1 session for an introductory call with any of the following coaches:</strong></p><li><strong>Hannah Hamwi</strong></li><li><strong>Raj Patel</strong></li><li><strong>Sebastian Sass</strong></li><li><strong>Josiah Crombie</strong></li>
                                        </Col>
                                        <Col lg="6"></Col>
                                        </>
                                        : "" : ""
                                      }
                                  </Row>
                                  
                              </CardHeader>
                              <CardBody>
                                        
                                      <Row>
                                        {
                                          filteredData.length ? filteredData.map((val) => {
                                            return val.label != "All Coaches" && <Col xl="4"  lg="6" md="12" >
                                              <Card className="mb-4 shadow-lg w-100">
                                                <CardBody>
                                                  {/* <Row> */}
                                                    {/* <Col> */}
                                                      <div className="text-center d-flex justify-content-center">
                                                        {val.profilePicture ? <div className='d-flex justify-content-center align-items-center'><div className="card-profile-div-sm card-profile-div-md card-profile-div-lg-coaches ">
                                                              <div className="card-profile-image  ">
                                                              
                                                                  <div className="profile-picture-sm profile-letter-md profile-picture-md profile-picture-lg-coaches" style={{
                                                                    backgroundImage: `url("${`https://storage.googleapis.com/pre-dental-tracker.appspot.com/profile-picture/${val.profilePicture}`}")`
                                                                  }}></div>
                                                              
                                                              </div>
                                                          </div> </div>:
                                                          <div className="card-profile-image d-flex justify-content-sm-center align-items-center">
                                                              {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                                              
                                                              <div className={"rounded-circle profile-letter-sm-primary profile-picture-md profile-picture-lg-coaches profile-letter-lg-primary-coaches " + props.bgColor }>{val.name.trim()[0]}</div>
                                                              {/* </a> */}
                                                          </div>}
                                                      </div>
                                                    {/* </Col>
                                                    <Col className="d-flex aligh-items-center"> */}
                                                      <div className="text-center">
                                                        <h2 className="mt-2 mb-1 font-weight-bolder text-default">{val.name}</h2>
                                                        <h3 className="mb-1 font-weight-bolder text-sm text-orange">{val.coachTitle}</h3>
                                                        <h3 className="text-sm text-gray">{val.coachPosition}</h3>

                                                        
                                                      </div>
                                                    {/* </Col> */}

                                                  {/* </Row> */}
                                                  <Row className="mt-2 pl-3 pr-3">
                                                      <Col md="6 mt-1 p-1"><Button onClick={e => toggleCoachDetails(e, val)} className="w-100 btn-sm" color="success"><i className="fas fa-user"></i> Coach Details</Button></Col>
                                                      <Col md="6 p-1" className="mt-1"><Button onClick={e => toggleScheduleTutorModal(e, val)} className="w-100 btn-sm" color="info"><i className="fas fa-calendar-alt"></i> Schedule Tutor</Button></Col>
                                                  </Row>
                                                  
                                                 
                                                </CardBody>
                                              </Card>
                                            </Col>
                                          })
                                          :""
                                        }
                                      </Row>
                                      {/* <Fragment>
                                          <div className="" style={{"height":"900px"}}>

                                              

                                              <Calendar
                                                  views={views}
                                                  // dayLayoutAlgorithm = 'no-overlap'
                                                  defaultDate={defaultDate}
                                                  localizer={localizer}
                                                  events={events}
                                                  startAccessor="start"
                                                  endAccessor="end"
                                                  showMultiDayTimes
                                                  style={{ height: "100%" }}
                                                  onSelectEvent={onSelectEvent}
                                                  // onSelectSlot={handleSelectSlot}
                                                  // selectable

                                                  popup

                                                  eventPropGetter={eventPropGetter}
                                                  step={60}
                                                  messages={{agenda: 'Schedules', event: 'Coaches'}}
                                              />
                                          </div>
                                      </Fragment> */}
                              </CardBody>
                              <CardFooter></CardFooter>
                          </Card>
                        </Col>
                      </Row>
                    :  mc == "My Requests" ?
                     <RequestsTable />
                    : 
                    <>
                      <Row>
                        <Col className=" d-flex justify-content-center">
                          <Nav tabs>
                            {historyopt.length > 0
                              ? historyopt.map((c, i) => {
                                  return (
                                    <NavItem className="ml-1" key={i}>
                                      <NavLink
                                        key={c}
                                        className={classnames({
                                          active: activeTab === c,
                                        })}
                                        onClick={() => {
                                          toggle(c);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {c}
                                      </NavLink>
                                    </NavItem>
                                  );
                                })
                              : ""}
                          </Nav>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12" className="mt-3 p-2">
                          <TabContent activeTab={activeTab}>
                            {historyopt.map((c, i) => {
                              return (
                                <TabPane tabId={c} key={i}>
                                  {c == "Pending" ? (
                                    <ScheduleItem
                                      status="pending"
                                      color="warning"
                                    />
                                  ) : c == "Approved" ? (
                                    <ScheduleItem
                                      status="approved"
                                      color="success"
                                    />
                                  ) : c == "Canceled" ? (
                                    <ScheduleItem
                                      status="canceled"
                                      color="danger"
                                    />
                                  ) : (
                                    <ScheduleItem
                                      status="disapproved"
                                      color="danger"
                                    />
                                  )}
                                </TabPane>
                              );
                            })}
                          </TabContent>
                        </Col>
                      </Row>
                    </>
                    }
                  </TabPane>

                 ))
                 }

              </TabContent>
          </Col>
        </Row>
       
        {/* <Modal
          isOpen={modal}
          toggle={modalToggle}
          backdrop={backdrop}
          size="xl"
          centered
        >
            <ModalHeader toggle={modalToggle}>
                {selectedEvent ?  selectedEvent.title : " "}
            </ModalHeader>
            <ModalBody className="pt-1">
              
                { 
                    selectedEvent ?
                  
                        <Card>
                            <CardBody>
                            <Row>

                            <Col lg="6 pb-4">

                              <h2 className="text-dark mb-4">Schedule Information</h2>
                              <p className="text-dark mb-1"><strong>Date:</strong> {moment(selectedEvent.startLabel).format('ll') != moment(selectedEvent.endLabel).format('ll') ? moment(selectedEvent.startLabel).format('ll')+" - "+moment(selectedEvent.endLabel).format('ll'):  moment(selectedEvent.startLabel).format('ll') }</p>
                              <p className="text-dark"><strong>Time:</strong> {moment(selectedEvent.startLabel).format('h:mm A')+" - "+moment(selectedEvent.endLabel).format('h:mm A')}</p>

                              <FormGroup tag="fieldset" >
                                  <h3 >Select type of hour to use</h3>

                                  {
                                      requestType.map((t,ii)=>{
                                      return <FormGroup check key={ii}>
                                          <Label check>
                                              <Input 
                                                  type="radio" 
                                                  name="radio2" 
                                                  value={t.value}
                                                  checked={t.value === selectedType}
                                                  onChange={(e) => handleSelectType(e,t.value)}
                                              />
                                              {
                                                  t.value == "30FT" ?
                                                  
                                                  <>
                                                  {t.label + " "}  
                                                  <span className="fa fa-info-circle" id={"freeinfo"} ></span>
                                                  <UncontrolledTooltip placement="right" target={"freeinfo"}>
                                                      Only choose the 30-minute bonus session if it has already been
                                                      approved. These sessions should be reserved for quarterly
                                                      check-ins or for general coaching when you have exhausted your
                                                      premium hours.
                                                  </UncontrolledTooltip>
                                                  </>
                                                  :
                                                  ' '+t.label 
                                              }
                                          </Label>
                                      </FormGroup>
                                      })
                                  }

                              </FormGroup>

                              <h3 >Select schedule</h3>

                              {
                                  selectedType == "1HPT"
                                      ?
                                      schedulePerHour ? 
                                          schedulePerHour.map((v,i) => (
                                            
                                                  <Button key={i} size="md" color="primary"  disabled={!v.status ? false : true} className="m-2"  onClick={(e) => handleSelectSchedule(v)} title={!v.status ? "Available" : "Unavailable"} >
                                                    
                                                      {
                                                          moment(v.start, "MMMM D, YYYY h:mm A").format('h:mm A')+' - '+moment(v.end, "MMMM D, YYYY h:mm A").format('h:mm A')
                                                      }
                                                  </Button>
                                          
                                          ))

                                      : ""
                                  :  schedulePerMinute ? 
                                      schedulePerMinute.map((v,i) => (
                                        
                                              <Button key={i} color="warning" size="sm" className="m-2" disabled={!v.status ? false : true}  onClick={(e) => handleSelectSchedule(v)} title={!v.status ? "Available" : "Unavailable"}>
                                                  {
                                                      moment(v.start).format('h:mm A')+' - '+moment(v.end).format('h:mm A')
                                                  }
                                              </Button>
                                        
                                      ))

                                  : ""
                              }

                              </Col>   

                                <Col lg="6">
                                    <h2 className="text-dark mb-4">Coach Details</h2>
                                    <Media className="align-items-center mb-3">
                                        {
                                            typeof selectedEvent.details.profilePicture != "undefined" ? (
                                                <div className="media d-flex justify-content-center align-items-center">
                                                    <div className='float-lg-left m-3 d-flex justify-content-center align-items-center'>
                                                      <div className="card-profile-div-sm card-profile-div-md card-profile-div-lg ">
                                                        <div className="card-profile-image  ">
                                                        
                                                            <div className="profile-picture-sm profile-letter-md profile-picture-md profile-picture-lg" style={{backgroundImage: `url("https://storage.googleapis.com/pre-dental-tracker.appspot.com/profile-picture/` + selectedEvent.details.profilePicture+`")`}}></div>
                                                        
                                                        </div>
                                                    </div> </div>
                                                    <div className="media-body ">
                                                        <div>
                                                          <h2 className="mt-0 text-dark">{selectedEvent.title}</h2>
                                                          <h3 className="text-gray">{selectedEvent.details.email}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                        
                                            ) : 
                                            (
                                                <div className="media">
                                                    <div className="rounded-circle profile-letter-all-users mr-2" 
                                                        style={{
                                                        width: "60px",
                                                        height: "60px",
                                                            fontSize: "16px"
                                                            }}>
                                                            {selectedEvent.title !== undefined ? selectedEvent.title.trim()[0].toUpperCase() : "1" }
                                                    </div>
                                                    <div className="media-body">
                                                      <h2 className="mt-0 text-dark">{selectedEvent.title}</h2>
                                                      <h3 className="text-gray">{selectedEvent.details.email}</h3>
                                                    
                                                    </div>
                                                </div>
                                            )
                                        }
                                        
                                    </Media>
                                    <h3 className="mt-0 text-dark">{selectedEvent.details.coachTitle}</h3>
                                    <h3 className="mt-0 text-gray">{selectedEvent.details.coachDentalSchool}</h3>
                                    <h3 className="text-info">{selectedEvent.details.coachPosition}</h3><hr></hr>
                                    <p  style={{whiteSpace:"pre-wrap"}} className="pb-1">{selectedEvent.details.coachDescription}</p>
                                </Col>
                               
                            </Row>
                            </CardBody>
                        </Card>
                    :""
                }
                <Modal
                    isOpen={nestedModal}
                    toggle={toggleNested}
                    
                >
                    <ModalHeader>Submit Schedule Request</ModalHeader>
                    <ModalBody>
                        {
                           selectedEvent ? 
                           <>
                            <p><b>Coach:</b> {selectedEvent.details.name}</p>
                            <p><b>Used Type:</b> {requestType.map((t,i) => ( t.value == selectedType ? t.label :"") )}</p>
                            <p><b>Date:</b> {selectedSchedule ? moment(selectedSchedule.start, "MMMM D, YYYY h:mm A").format('ll') != moment(selectedSchedule.end, "MMMM D, YYYY h:mm A").format('ll') ? moment(selectedSchedule.start, "MMMM D, YYYY h:mm A").format('ll')+" - "+moment(selectedSchedule.end, "MMMM D, YYYY h:mm A").format('ll'):  moment(selectedSchedule.start, "MMMM D, YYYY h:mm A").format('ll') : "" }</p>
                            <p><b>Time:</b> {selectedSchedule ? moment(selectedSchedule.start, "MMMM D, YYYY h:mm A").format('h:mm A')+ " - "+ moment(selectedSchedule.end, "MMMM D, YYYY h:mm A").format('h:mm A') : ""}</p>
                           
                           </>
                           : ""
                        }

                        <b>What do you want to cover in this session?<span className='text-danger'>*</span></b>
                        
                          <Input
                              id="sessionDescription"
                              name="sessionDescription"
                              className="mt-3"
                              placeholder="Type here..."
                              rows="5"
                              type="textarea"
                              onChange={e => setSessionDescription(e.target.value)}
                          />

                    </ModalBody>
                    <ModalFooter>
                    <Button color="danger" size="" onClick={toggleNested}>
                        Cancel
                    </Button>{' '}
                    <Button color="success" size=""  disabled={sessionDescription? false: true} onClick={handleRequestTutorOnClick}>
                        Submit Request
                    </Button>{' '}
                    
                    </ModalFooter>
                </Modal>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </Modal> */}

        <Modal
            isOpen={coachDetailsModal}
            toggle={toggleCoachDetails}   
            size="md"
        >
            <ModalHeader>Profile</ModalHeader>
            <ModalBody>
              {coachData.name ? <>
                <div className="text-center d-flex justify-content-center">
                  
                  {coachData.profilePicture ? <div className='d-flex justify-content-center align-items-center'><div className="card-profile-div-sm card-profile-div-md card-profile-div-lg-coaches ">
                        <div className="card-profile-image  ">
                        
                            <div className="profile-picture-sm profile-letter-md profile-picture-md profile-picture-lg-coaches" style={{
                              backgroundImage: `url("${`https://storage.googleapis.com/pre-dental-tracker.appspot.com/profile-picture/${coachData.profilePicture}`}")`
                            }}></div>
                        
                        </div>
                    </div> </div>:
                    <div className="card-profile-image d-flex justify-content-sm-center align-items-center">
                        {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                        
                        <div className={"rounded-circle profile-letter-sm-primary profile-picture-md profile-picture-lg-coaches profile-letter-lg-primary-coaches " + props.bgColor }>{coachData.name.trim()[0]}</div>
                        {/* </a> */}
                    </div>}
                </div>
                <div className="text-center mt-3">
                  <h2 className="mt-2 mb-1 font-weight-bolder text-default">{coachData.name}</h2>
                  <h3 className="mb-1 font-weight-bolder text-sm badge badge-pill badge-warning">{coachData.coachTitle}</h3>
                  <h3 className="text-sm text-gray">{coachData.coachPosition}</h3>
                  <h3 className="text-sm text-info">{coachData.coachDentalSchool}</h3>
                  
                  <hr className="mt-5 mb-3"></hr>
                  <Row>
                    <Col className="text-center"><h2 className="text-sm"><i className="fas fa-envelope"></i>&nbsp;Email <br></br>{coachData.email}</h2></Col>
                    <Col className="text-center"><h2 className="text-sm"><i className="fas fa-phone"></i>&nbsp;Phone <br></br>{formatPhoneNumber(coachData.phoneNumber)}</h2></Col>
                  </Row>
                  <hr className="mt-2 mb-5"></hr>
                  <p  style={{whiteSpace:"pre-wrap"}} className="pb-1 lh-150">{coachData.coachDescription}</p>
                </div>
                
              </> : ""}
             
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" size="" onClick={toggleCoachDetails}>
                  Close
              </Button>
            
            </ModalFooter>
        </Modal>

        <Modal
            isOpen={scheduleTutorModal}
            toggle={toggleScheduleTutorModal}   
            size="xl"
        >
            <ModalHeader>{coachData.name}</ModalHeader>
            <ModalBody>
            
            {coachData.name ? <>
              <Row> 
                <Col sm="12" md="12"></Col>
                <Col sm="12" md="6" className="border border-left-0  border-top-0 border-bottom-0 border-right-1" >
                  {/* <HStack spacing={10} style={{ height: 320 }} alignItems="flex-start" wrap> */}
                    <h3 className=" font-weight-bolder text-gray mb-0 ml-3">{coachData.name}</h3>
                    <h2 className=" font-weight-bolder text-default mb-0 ml-3">Select a Date & Time</h2>
                    <h2 className=" font-weight-bolder text-info mb-4 ml-3"><i class="fas fa-clock"></i> Local Timezone - {moment.tz.guess()}</h2>
                    <Calendar compact bordered onSelect={onSelectEvent} renderCell={(date)=>renderCell(date, availableSchedule, coachData)} style={{ width: "100%", fontWeight:"bolder"}} cellClassName={date => moment(moment(date).format('MMM D, YYYY'),'MMM D, YYYY').isBefore(moment(),"day") ? 'custom-height-cell custom-height-cell-disabled bg-light' : 'custom-height-cell'} />
                    <Divider vertical></Divider>
                    {/* <TodoList /> */}
                  {/* </HStack> */}
                </Col>
                <Col sm="12" md="6">
                  
                  <h3 className="font-weight-bolder mb-0 text-default mb-2">
                    {
                      // uastudent ? 
                        userInfo.tutorHours == "0"
                        ? 
                        <>
                          
                          <><span className="text-danger important">0</span> DAT Premium Hours</>
                          <Button color="success" onClick={toggleBuyTutoringHoursModal} className="ml-2 mr-2 btn-sm" ><i className="fas fa-shopping-cart"></i> Buy</Button> 
                        </>
                        : <><span className="text-warning important">{userInfo.tutorHours}</span> DAT Premium Hours</>
                      // : ""
                    }
                  </h3>
                  {/* {
                    uastudent ?  */}
                  {props.userDetails.ultimateAdvisingStudent? 
                    <h3 className="font-weight-bolder mb-3 text-default mt-1">  
                    <span className="text-warning important">30 minutes</span> Admission Assistance Per Week <span className="fa fa-question-circle text-primary" id={"freeinfo"} role="button" ></span>
                      <UncontrolledTooltip placement="right" target={"freeinfo"} >
                        Only choose the 30-minute bonus session if it has already been
                          approved. These sessions should be reserved for quarterly
                          check-ins or for general coaching when you have exhausted your
                          premium hours.
                      </UncontrolledTooltip>
                    </h3>
                  : ""}
                  <ButtonGroup className='w-100'>
                   {props.userDetails.ultimateAdvisingStudent === "true" || props.userDetails.ultimateAdvisingStudent === true ? <Button color={activeTutorTime == "30Minute" ? "info" : "white"} disabled={props.userDetails.ultimateAdvisingStudent? "" : "disabled"} onClick={()=>handleSwitchTutorTime("30Minute")}>30 Minute</Button> : ""}
                    <Button color={activeTutorTime == "60Minute" ? "info" : "white"} onClick={()=>handleSwitchTutorTime("60Minute")}>60 Minute</Button>
                  </ButtonGroup>

                  <TabContent activeTab={activeTutorTime}>
              
                    <TabPane tabId="30Minute" >
                      <Row className="mt-2">
                        {pendingRequest30Minute && schedulePerMinute.length? <Col md="12 mt-3 mb-3"><h2 className="text-danger text-sm">You cannot make a request as you already have a pending 30-minute coaching session.</h2> </Col>: ""}
                        {
                          schedulePerMinute.length?
                            schedulePerMinute.map((v,i) => (
                              
                              <Col md="6 text-center">
                                
                                <Button key={i} color="warning" size="sm" className="mb-1 mt-1 w-100" disabled={!v.status ? pendingRequest30Minute ? true : false : true}  onClick={(e) => handleSelectSchedule(v)} title={!v.status ? "Available" : "Unavailable"}>
                                    {
                                        moment(v.start, "MMMM D, YYYY h:mm A").format('h:mm A')+' - '+moment(v.end, "MMMM D, YYYY h:mm A").format('h:mm A')
                                    }
                                </Button>
                              </Col>
                        
                            ))
                            :
                            <Col md="12 text-center">
                              <h2 className="text-sm text-gray text-center mt-4">No Available Schedule.</h2>
                            </Col>
                        }
                      </Row>
                        
                    </TabPane>

                    <TabPane tabId="60Minute">
                      <Row className="mt-2">
                        {
                          schedulePerHour.length?

                            schedulePerHour.map((v,i) => (
                              
                              <Col md="6 text-center">
                                
                                <Button key={i} size="sm" color="primary"  disabled={!v.status ? false : true} className="mb-1 mt-1 w-100"  onClick={(e) => handleSelectSchedule(v)} title={!v.status ? "Available" : "Unavailable"} >
                                  
                                    {
                                        moment(v.start, "MMMM D, YYYY h:mm A").format('h:mm A')+' - '+moment(v.end, "MMMM D, YYYY h:mm A").format('h:mm A')
                                    }
                                </Button>
                              </Col>
                                      
                            )):
                            <Col md="12 text-center">
                              <h2 className="text-sm text-gray text-center mt-4">No Available Schedule.</h2>
                            </Col>
                        }
                      </Row>
                    </TabPane>

                  </TabContent>



                  <Modal
                    isOpen={nestedModal}
                    toggle={toggleNested}
                    
                >
                    <ModalHeader>Submit Schedule Request</ModalHeader>
                    <ModalBody>
                        {
                           selectedEvent ? 
                           <>
                            <p><b>Coach :</b> {selectedEvent.details.name}</p>
                            <p><b>Used Type :</b> {requestType.map((t,i) => ( t.value == selectedType ? t.label :"") )}</p>
                            <p><b>Date :</b> {selectedSchedule ? moment(selectedSchedule.start, "MMMM D, YYYY h:mm A").format('ll') != moment(selectedSchedule.end, "MMMM D, YYYY h:mm A").format('ll') ? moment(selectedSchedule.start, "MMMM D, YYYY h:mm A").format('ll')+" - "+moment(selectedSchedule.end, "MMMM D, YYYY h:mm A").format('ll'):  moment(selectedSchedule.start, "MMMM D, YYYY h:mm A").format('ll') : "" }</p>
                            <p><b>Time :</b> {selectedSchedule ? moment(selectedSchedule.start, "MMMM D, YYYY h:mm A").format('h:mm A')+ " - "+ moment(selectedSchedule.end, "MMMM D, YYYY h:mm A").format('h:mm A') : ""}</p>
                            <p><b>Description :</b> {selectedSchedule ? selectedSchedule.description : ""}</p>
                           
                           </>
                           : ""
                        }

                        <b>What do you want to cover in this session?<span className='text-danger'>*</span></b>
                        
                          <Input
                              id="sessionDescription"
                              name="sessionDescription"
                              className="mt-3"
                              placeholder="Type here..."
                              rows="5"
                              type="textarea"
                              onChange={e => setSessionDescription(e.target.value)}
                          />

                    </ModalBody>
                    <ModalFooter>
                    <Button color="danger" size="" onClick={toggleNested}>
                        Cancel
                    </Button>{' '}
                    <Button color="success" size=""  disabled={sessionDescription? false: true} onClick={handleRequestTutorOnClick}>
                        Submit Request
                    </Button>{' '}
                    
                    </ModalFooter>
                </Modal>
                
                  
                </Col>
              </Row>
              </> : ""
            }
             
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" size="" onClick={toggleScheduleTutorModal}>
                  Close
              </Button>
            
            </ModalFooter>
        </Modal>

      </Container>
      
       <BuyTutoringHoursModal
        modalBuyTutoringHoursModal={modalBuyTutoringHoursModal}
        toggleBuyTutoringHoursModal={toggleBuyTutoringHoursModal}
      />

      {/* <Plans ></Plans> */}


    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
    fetchUserDetails:state.fetchUserDetails,
    fetchAvailableSchedule: state.fetchAvailableSchedule,
    createRequestTutor: state.createRequestTutor,
    fetchRequestTutor: state.fetchRequestTutor,
    cancelRequestTutor: state.cancelRequestTutor,
    fetchRequestTutorHistory: state.fetchRequestTutorHistory,
    buyTutoringHoursModalStatus: state.buyTutoringHoursModalStatus,
    fetchCoaches: state.fetchCoaches,
    fetchSchedule: state.fetchSchedule,
    fetchLtpItem: state.fetchLtpItem,
    updateTutoringHoursAvailable : state.updateTutoringHoursAvailable,
    cancelRequestTutor: state.cancelRequestTutor
  };
};

export default connect(mapStateToProps)(ScheduleTutor);
