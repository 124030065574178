import React, {useEffect} from 'react'
import {Route, Redirect, useHistory} from 'react-router-dom'
import {connect, useDispatch} from 'react-redux'
import ClientLayout from 'layouts/Client'
import {
    fetchRequestTutorHistory,
  } from "redux/ScheduleTutor/ScheduleTutorActions";
import Swal from 'sweetalert2'
import { updateUserIntroductoryCall } from 'redux/Users/UsersActions';
const  ClientRoute = ({component:Component, authenticated, admin, userDetails,fetchRequestTutorHistoryState, ...rest}) => {

    const dispatch = useDispatch()
    const history = useHistory();

    useEffect(() => {
            
        if(userDetails){
            if(userDetails.ultimateAdvisingStudent === true || userDetails.ultimateAdvisingStudent === "true"){
                dispatch(fetchRequestTutorHistory({ studentHandle: userDetails.handle }))
            }
        }
        

    }, [userDetails])
    
    useEffect(() => {
        if(history.location.pathname != "/client/schedule-tutor"){
            if(fetchRequestTutorHistoryState.fetchrequesttutorhistory.length){

                if(fetchRequestTutorHistoryState.fetchrequesttutorhistory[0].length == 0 && (userDetails.introductoryCall == false || typeof userDetails.introductoryCall == "undefined") && (userDetails.ultimateAdvisingStudent === true || userDetails.ultimateAdvisingStudent === "true" ) == true){
               
                    // if(userDetails.ultimateAdvisingStudent === true || userDetails.ultimateAdvisingStudent === "true" ){
                    
                        Swal.fire({

                            title: 'Introductory Call',
                            
                            html: "<p style='font-weight: bold;'>Schedule a 1:1 session with any of the following coaches:</p><li><strong>Hannah Hamwi</strong></li><li><strong>Raj Patel</strong></li><li><strong>Sebastian Sass</strong></li><li><strong>Josiah Crombie</strong></li>",
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Schedule',
                            cancelButtonText: 'Completed'

                        }).then((result) => {
                            console.log(result)
                            if (result.isConfirmed) {
                            
                            
                                
                                history.push("/client/schedule-tutor")
                            }
                            else{
                                if(result.dismiss == "cancel"){
                                    
                                    dispatch(updateUserIntroductoryCall({handle : userDetails.handle, introductoryCall: true}))
                                }
                            }
            
                        })
                    // }
                }

            }
        }

    }, [fetchRequestTutorHistoryState])

    return (
        <Route {...rest}
            render = {(props) => {
                
                if (authenticated === true ){
                    if (admin === true ){
                        
                        return <Redirect to = "/admin/dashboard"/>
                    } else {
                        
                        return <ClientLayout><Component {...props}/></ClientLayout>
                    }
                } else {
                    return <Redirect to = "/auth/login"/>
                }
            } 
            }
        />
)}
const mapStateToProps = (state) => ({
    authenticated: state.loginAuth.authenticated,
    admin: state.loginAuth.admin,
    userDetails : state.loginAuth.userDetails,
    fetchRequestTutorHistoryState : state.fetchRequestTutorHistory
})


export default connect(mapStateToProps)(ClientRoute)
