import { 
    CREATE_PROFILE_PICTURE_FAILURE,
    CREATE_PROFILE_PICTURE_REQUEST,
    CREATE_PROFILE_PICTURE_RESET,
    CREATE_PROFILE_PICTURE_SUCCESS,
    UPDATE_USER_PROFILE_FAILURE,
    UPDATE_USER_PROFILE_REQUEST,
    UPDATE_USER_PROFILE_RESET,
    UPDATE_USER_PROFILE_SUCCESS,
    FETCH_UNIVERSITIES_REQUEST,
    FETCH_UNIVERSITIES_SUCCESS,
    FETCH_UNIVERSITIES_FAILURE,

    UPDATE_USER_PHONE_NUMBER_FAILURE,
    UPDATE_USER_PHONE_NUMBER_REQUEST,
    UPDATE_USER_PHONE_NUMBER_RESET,
    UPDATE_USER_PHONE_NUMBER_SUCCESS,
    UPDATE_USER_COMMUNITY_SETTINGS_FAILURE,
    UPDATE_USER_COMMUNITY_SETTINGS_REQUEST,
    UPDATE_USER_COMMUNITY_SETTINGS_RESET,
    UPDATE_USER_COMMUNITY_SETTINGS_SUCCESS,

    UPDATE_USER_INTRODUCTORY_CALL_FAILURE,
    UPDATE_USER_INTRODUCTORY_CALL_REQUEST,
    UPDATE_USER_INTRODUCTORY_CALL_RESET,
    UPDATE_USER_INTRODUCTORY_CALL_SUCCESS
} from "./UsersTypes"


const initialProfilePictureState = {
    profilepicture: [],
    loading : false,
    done : false
}

export const createProfilePictureReducer = (state = initialProfilePictureState, action) => {
    switch(action.type){
        case CREATE_PROFILE_PICTURE_RESET:
            return {
                ...state,
                done : false
            }
        case CREATE_PROFILE_PICTURE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_PROFILE_PICTURE_SUCCESS:
            return {
                loading: false,
                profilepicture: [action.payload],
                done : true
            }
        case CREATE_PROFILE_PICTURE_FAILURE:
            return {
                loading : false,
                profilepicture : [],
                error: action.payload,
                done : true
            }
        default: return state;
    }
}


const initialUserProfileState = {
    userprofile: [],
    loading : false,
    done: false
}

export const updateUserProfileReducer = (state = initialUserProfileState, action) => {
    switch(action.type){
        case UPDATE_USER_PROFILE_RESET:
            return {
                ...state,
                done : false
            }
        case UPDATE_USER_PROFILE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_USER_PROFILE_SUCCESS:
            return {
                loading: false,
                done : true,
                userprofile: [action.payload]
            }
        case UPDATE_USER_PROFILE_FAILURE:
            return {
                loading : false,
                userprofile : [],
                done : true,
                error: action.payload
            }
        default: return state;
    }
}
const initialUpdateUserCommunitySettingsState = {
    updateusercommunitysettings: [],
    loading : false,
    done: false
}

export const updateUserCommunitySettingsReducer = (state = initialUpdateUserCommunitySettingsState, action) => {
    switch(action.type){
        case UPDATE_USER_COMMUNITY_SETTINGS_RESET:
            return {
                ...state,
                done : false
            }
        case UPDATE_USER_COMMUNITY_SETTINGS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_USER_COMMUNITY_SETTINGS_SUCCESS:
            return {
                loading: false,
                done : true,
                updateusercommunitysettings: [action.payload]
            }
        case UPDATE_USER_COMMUNITY_SETTINGS_FAILURE:
            return {
                loading : false,
                updateusercommunitysettings : [],
                done : true,
                error: action.payload
            }
        default: return state;
    }
}



const initialFetchUniversitiesState = {
    universities : [],
    loading: false,

}

export const fetchUniversitiesReducer = (state = initialFetchUniversitiesState, action) => {
    switch (action.type) {
        case FETCH_UNIVERSITIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_UNIVERSITIES_SUCCESS:
            return {
                loading: false,
                universities: action.payload
            }
        case FETCH_UNIVERSITIES_FAILURE:
            return {
                loading: false,
                universities: [],
                error: action.payload
            }
        default: return state;
    }
}


const initialUserPhoneNumberState = {
    userphonenumber: [],
    loading : false,
    done: false
}

export const updateUserPhoneNumberReducer = (state = initialUserPhoneNumberState, action) => {
    switch(action.type){
        case UPDATE_USER_PHONE_NUMBER_RESET:
            return {
                ...state,
                userphonenumber: [],
                done : false
            }
        case UPDATE_USER_PHONE_NUMBER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_USER_PHONE_NUMBER_SUCCESS:
            return {
                loading: false,
                done : true,
                userphonenumber: [action.payload]
            }
        case UPDATE_USER_PHONE_NUMBER_FAILURE:
            return {
                loading : false,
                userphonenumber : [],
                done : true,
                error: action.payload
            }
        default: return state;
    }
}

const initialUserIntroductoryCallState = {
    userintroductorycall: [],
    loading : false,
    done: false
}

export const updateUserIntroductoryCallReducer = (state = initialUserIntroductoryCallState, action) => {
    switch(action.type){
        case UPDATE_USER_INTRODUCTORY_CALL_RESET:
            return {
                ...state,
                userintroductorycall: [],
                done : false
            }
        case UPDATE_USER_INTRODUCTORY_CALL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_USER_INTRODUCTORY_CALL_SUCCESS:
            return {
                loading: false,
                done : true,
                userintroductorycall: [action.payload]
            }
        case UPDATE_USER_INTRODUCTORY_CALL_FAILURE:
            return {
                loading : false,
                userintroductorycall : [],
                done : true,
                error: action.payload
            }
        default: return state;
    }
}